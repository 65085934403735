import React, { useState } from 'react';

import styled from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { ScheduleItem } from './frame/schedule-item';
import { ScheduleItemUpdateContainer } from '../schedule-item-update';
import { AlertEmptyElem } from '../../common/alert-empty';

export const Component: React.FC<{
  isLoading?: boolean;
  data?: { date: string; time: string[]; oldDate: string[] }[];
  isError?: boolean;
  errorMessage?: string;
  isSubmitDisabled: Function;
  isSuccess: boolean;
  handleDelete: Function;
}> = ({ isLoading, isError, errorMessage, isSuccess, data, handleDelete }) => {
  const [activeUpdateId, setActiveUpdateId] = useState<string>();

  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem type="success" tid="SCHEDULE.WEEKEND.SUCCESS_DELETE" />
      )}

      <Container>
        <WeekendContainer>
          {Array.isArray(data) && (
            <>
              {data.map((e) => (
                <div onClick={() => setActiveUpdateId(e.date)}>
                  {e.date !== activeUpdateId && (
                    <ScheduleItem
                      key={e.date}
                      day={e.date}
                      time={e.time}
                      deleteAction={() => {
                        handleDelete(e.oldDate);
                      }}
                    />
                  )}
                  {e.date === activeUpdateId && (
                    <ScheduleItemUpdateContainer
                      day={e.date}
                      oldDate={e.oldDate}
                      time={e.time}
                      key={e.date}
                    />
                  )}
                </div>
              ))}
            </>
          )}
          {data && data?.length < 1 && <AlertEmptyElem />}
        </WeekendContainer>
      </Container>
    </>
  );
};

const WeekendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.ALERT]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(7)};

  @media screen and (max-width: 1160px) {
    margin-bottom: ${Spacing(50)};
  }
`;
