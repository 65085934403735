import { CONSULTATION_STATUS } from '../../data/consultation/constant';
import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const action = (paymentId: string) => {
  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL(paymentId),
  });
};

export const cancelAction = (id: string, cancelInfo: string) => {
  return HttpRequest({
    method: API.PATCH.TYPE,
    url: API.PATCH.URL(id),
    data: {
      cancellationReason: cancelInfo,
      status: CONSULTATION_STATUS.CANCEL,
    },
  });
};
