import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { LayoutElem } from '../../common/layout';

import { GridElem } from '../../common/grid';
import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';

import { AuthVerificationContainer } from '../../epic/auth-verification';
import { AUTH_TYPE } from '../../data/auth/constant';
import { AuthHeader } from '../../common/auth-header';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageElem>
      <LayoutAppElem oneColumn>
        <GridElem spacing={6}>
          <AuthHeader />
          <LayoutElem size="small" direction="column" oneColumn>
            <AuthVerificationContainer type={AUTH_TYPE.EMAIL} />
          </LayoutElem>
        </GridElem>
      </LayoutAppElem>
    </PageElem>
  );
};
