import React from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { Component } from './component';
import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';
import { CONSULTATION_PAGE_PATH_DYNAMIC } from '../../page/consultation';
import { getAction } from './action';
import { CONSULTATION_DATA_RAW_INTER } from '../consultation-list/constant';
import { CATEGORY_VIEW } from '../profile-psycho-create-category/constant';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const Container: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const onSuccess = (data: CONSULTATION_DATA_RAW_INTER) => {
    const ecommerceData = {
      event: 'purchase',
      ecommerce: {
        transaction_id: String(data.id),
        value: Number(data.price),
        currency: 'UAH',
        items: [
          {
            item_id: String(data.id),
            item_name: CATEGORY_VIEW[data.category],
            item_category: CATEGORY_VIEW[data.category],
            price: Number(data.price),
          },
        ],
      },
    };

    if (window?.dataLayer) {
      try {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push(ecommerceData);
      } catch {
      } finally {
        setTimeout(
          () => history.push(CONSULTATION_PAGE_PATH_DYNAMIC(data.id)),
          2000,
        );
      }
    }
  };

  const preFetch = useQuery([MODULE_NAME, id], () => getAction(id), {
    onSuccess: (data: CONSULTATION_DATA_RAW_INTER) => onSuccess(data),
  });

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  return (
    <Component
      errorMessage={getErrorMessage()}
      isError={!!getErrorMessage()}
      isLoading={preFetch.isLoading}
      isSuccess={preFetch.isSuccess}
    />
  );
};
