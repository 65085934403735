import { USER_ROLE } from '../../data/auth/constant';
import {
  PAYMENT_ITEM_LIST_DATA_RAW_INTER,
  PAYMENT_ITEM_LIST_DATA_INTER,
} from '../../data/payment/constant';

export const MODULE_NAME = 'SCHEDULE_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  GET: {
    TYPE: 'GET',
    URL: '/user/schedule',
  },
  DELETE: {
    TYPE: 'PATCH',
    URL: '/user/schedule/delete',
  },
};

export interface ACTION_RESPONSE_INTER
  extends PAYMENT_ITEM_LIST_DATA_RAW_INTER {}

export interface DATA_INTER extends PAYMENT_ITEM_LIST_DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface PROPS_TYPE {
  role?: USER_ROLE;
  isPaid?: boolean;
  consultationId?: string;
  timezone: number;
  duration: number;
  url: string;
  isCompleteClient: boolean;
  isCompletePsycho: boolean;
}

export interface SCHEDULE_RAW {
  schedule: string[];
}
