import { components, DropdownIndicatorProps } from 'react-select';
import { ReactComponent as IndicatorIcon } from '../../../asset/svg/common/selectIndicator.svg';
import { ReactComponent as IndicatorIconWhite } from '../../../asset/svg/common/selectIndicator-white.svg';
import { ReactComponent as CloseIcon } from '../../../asset/svg/common/close-white.svg';

export const multiValueContainer = ({ selectProps, data }: any) => {
  const label = data.label;
  const allSelected = selectProps.value;
  const index = allSelected.findIndex(
    (selected: any) => selected.label === label,
  );
  const isLastSelected = index === allSelected?.length - 1;
  const labelSuffix = isLastSelected ? '' : ', ';

  return (
    <span translate="no">
      {label}
      <span>{labelSuffix}</span>
    </span>
  );
};

export const ClearIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <CloseIcon />
    </components.ClearIndicator>
  );
};

export const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  const isOpen = props.selectProps.menuIsOpen;
  return (
    <components.DropdownIndicator {...props}>
      {isOpen ? <IndicatorIconWhite /> : <IndicatorIcon />}
    </components.DropdownIndicator>
  );
};
