import React, { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import {
  ACTION_ERROR_INTER,
  CONSULTATION_DATA_RAW_INTER,
  CONSULTATION_ITEM_DATA_INTER,
  MODULE_NAME,
} from './constant';
import { Component } from './component';
import { action } from './action';
import { convertConsultationList } from './convert';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
export const Container: React.FC<{}> = () => {
  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const timezone = auth.user ? auth.user.timezone : null;

  const preFetch = useInfiniteQuery({
    queryKey: [MODULE_NAME],
    queryFn: ({ pageParam = 1 }) => action(pageParam),
    getNextPageParam: (lastPage: any, allPages: any) => {
      return lastPage.length ? allPages.length + 1 : 1;
    },
    refetchOnWindowFocus: false,
  });

  const data = useMemo(() => {
    return convertConsultationList(
      preFetch?.data?.pages.reduce((acc: any, page: any) => {
        return [...acc, ...page];
      }, []) as CONSULTATION_DATA_RAW_INTER[],
      timezone,
    );
  }, [preFetch?.data]);
  const isLoading = () => {
    if (preFetch.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (preFetch.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const isIdle = () => {
    if (Array.isArray(preFetch.data?.pages.slice(-1)[0]) && isSuccess()) {
      const data = preFetch.data?.pages.slice(
        -1,
      )[0] as unknown as CONSULTATION_ITEM_DATA_INTER[];
      return !data.length;
    }
  };

  return (
    <Component
      data={data}
      isLoading={isLoading()}
      isError={isError()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      fetchNextPage={preFetch.fetchNextPage}
      isFetching={preFetch.isFetching}
      isIdle={isIdle()}
    />
  );
};
