export const MODULE_NAME = 'PAYMENT_LIST_FILTER_MODULE_NAME';

export const USER_OPTION_LIST_QUERY_KEY = 'USER_OPTION_LIST_QUERY_KEY';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (pageParam: string) => `/user/option-list/${pageParam}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface ACTION_RESPONSE_INTER {
  list: [name: string, id: string, phone: string, email: string];
}

export enum FORM_VALUE_ENUM {
  CONSULTATION_STATUS = 'consultationStatus',
  PAYMENT_STATUS = 'paymentStatus',
  DATE = 'date',
  USERS = 'users',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;
