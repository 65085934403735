import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import {
  ACTION_RESPONSE_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_TYPE,
  USER_OPTION_LIST_QUERY_KEY,
} from './constant';
import { Component } from './component';
import { action } from './action';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { convertData } from './convert';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { useSelector } from '../../lib/store';
import { FILTER_OPTION } from '../consultation-list-admin/constant';

const config = {
  [FORM_VALUE_ENUM.CONSULTATION_STATUS]: [],
  [FORM_VALUE_ENUM.PAYMENT_STATUS]: [],
};

export const Container: React.FC<{
  setOption: React.Dispatch<React.SetStateAction<FILTER_OPTION>>;
}> = ({ setOption }) => {
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [selectUserValue, setSelectUsertValue] = useState('');
  const [indentify, setIndentify] = useState<string | undefined>();
  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const toggleFilterOpen = () => {
    setIsOpenFilter((prev) => !prev);
  };

  const preFetchUser = useQuery(USER_OPTION_LIST_QUERY_KEY, () =>
    action(selectUserValue),
  );

  const validate = (values: FormikValues) => validation(values, config);

  const formik: FormikValues = useFormik({
    initialValues: {
      [FORM_VALUE_ENUM.USERS]: [],
    },
    validate,
    enableReinitialize: true,
    onSubmit: () => {},
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      preFetchUser.refetch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [selectUserValue]);

  useEffect(() => {
    setOption((prevOption: FILTER_OPTION) => ({
      ...prevOption,
      indentify,
    }));
  }, [formik.values, indentify]);

  const handleSelectUserChange = (newValue: any, _actionMeta: any) => {
    setSelectUsertValue(newValue);
  };

  const getData = useMemo(() => {
    const data = preFetchUser.data as unknown as ACTION_RESPONSE_INTER;

    if (data) {
      const converedData = convertData(data);

      return { ...converedData };
    }

    return undefined;
  }, [preFetchUser.data]);

  const isLoading = () => {
    if (preFetchUser.isLoading) {
      return true;
    }
  };

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const userOptionList = getData?.list;

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    formik.setFieldValue(name, e?.value);
  };

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    if (value == FORM_VALUE_ENUM.USERS) {
      const optionList = options?.filter(
        (item: SELECT_OPTION_ITEM_DATA) => item?.label == formik.values[value],
      )[0];
      return optionList ? optionList : null;
    }
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item?.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  return (
    <Component
      handleSelectUserChange={handleSelectUserChange}
      setFieldValue={setFieldValue}
      setValue={setValue}
      isLoading={isLoading()}
      getFieldError={getFieldError}
      isFieldError={isFieldError}
      setIndentify={setIndentify}
      userList={userOptionList}
      isOpenFilter={isOpenFilter}
      toggleOpenFilter={toggleFilterOpen}
    />
  );
};
