import { OPTION_INTER } from '../../data/user/constant';

export const convert = (values: any, name: string): any => {
  if (name === 'consultationStatus' && values?.[name]) {
    return values[name].map((e: OPTION_INTER) => e.value);
  }
  if (name === 'paymentStatus' && values?.[name]) {
    return values[name];
  }
  return [];
};

export const convertData = (data: any) => {
  if (!data) {
    return;
  }

  const converedData = data?.list.map((e: any) => {
    const convert = {
      label: e?.name,
      value: e?.id,
      phone: e?.phone,
      email: e?.email,
    };

    return convert;
  });

  return { list: converedData };
};
