import { TIMEZONE_ENUM } from '../../data/user/timezone';
import {
  convertDate,
  convertDateReverse,
  convertDayTime,
  convertTime,
} from '../../lib/lang/service';
import { convertDateToLocal } from '../../lib/util/dateConvert';
import { CATEGORY_VIEW } from '../profile-psycho/constant';
import {
  CONSULTATION_DATA_RAW_INTER,
  CONSULTATION_ITEM_DATA_INTER,
  CONSULTATION_ITEM_LIST_DATA_INTER,
  PAYMENT_ITEM_DATA_INTER,
} from './constant';

const convertPayment = (
  payment: PAYMENT_ITEM_DATA_INTER | null,
): 'PAID' | 'NOT_PAID' => {
  return payment?.isPaid ? 'PAID' : 'NOT_PAID';
};

export const convertConsultation = (
  consultation: CONSULTATION_DATA_RAW_INTER,
  timezone: TIMEZONE_ENUM,
): CONSULTATION_ITEM_DATA_INTER => {
  // const dateWithSpace = `${convertDate(consultation.date)} ${convertTime(consultation.date)}`

  const { time, day } = convertDateToLocal(consultation.date, timezone);

  return {
    ...consultation,
    date: day,
    time: time,
    category: CATEGORY_VIEW[consultation.category],
    paymentStatus: convertPayment(consultation.payment),
  };
};

export const convertConsultationList = (
  consultationList: CONSULTATION_DATA_RAW_INTER[],
  timezone: TIMEZONE_ENUM,
): CONSULTATION_ITEM_LIST_DATA_INTER => {
  return {
    list: consultationList?.map((consultation: CONSULTATION_DATA_RAW_INTER) => {
      return convertConsultation(consultation, timezone);
    }),
    isEmpty: !consultationList?.length,
  };
};
