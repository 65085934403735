import styled from 'styled-components';
import { useState } from 'react';

import { FILE_ENUM, FILE_TYPE_TYPE } from '../constant';
import { Spacing } from '../../../theme';
import { TextElem } from '../../text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import uploadDocumentSvg from '../../../asset/svg/common/document-upload.svg';
import playSvg from '../../../asset/svg/common/play.svg';

import { ModalElem } from '../../modal';
import { COLOR_ENUM } from '../../../theme/color';

const renderImagePreview = (url: string, onOpen: () => void): JSX.Element => (
  <>
    <ImageStyled onClick={onOpen} src={url} alt="uploaded" />
  </>
);

const renderVideoPreview = (url: string, onOpen: () => void): JSX.Element => (
  <VideoPreviewContainer onClick={onOpen}>
    <DotPlay>
      <PlayImg src={playSvg} />
    </DotPlay>
    <TextElem
      tid="COMMON.FILE.VIEW_VIDEO"
      type="regular"
      size="semiSmall"
      color="default"
    />
  </VideoPreviewContainer>
);

const renderDocumentPreview = (url: string): JSX.Element => {
  const open = () => {
    window.open(url, '_blank');
  };
  return (
    <UploadedContainer onClick={open}>
      <UploadImg src={uploadDocumentSvg} />
      <TextElem
        tid="COMMON.FILE.VIEW_FILE"
        type="regular"
        size="semiSmall"
        color="default"
      />
    </UploadedContainer>
  );
};

const renderFilePreview = (
  uploadType: FILE_TYPE_TYPE,
  url: string,
  onOpen: any,
): JSX.Element => {
  switch (uploadType) {
    case FILE_ENUM.IMAGE:
      return renderImagePreview(url, onOpen);
    case FILE_ENUM.VIDEO:
      return renderVideoPreview(url, onOpen);
    case FILE_ENUM.DOCUMENT:
      return renderDocumentPreview(url);
    default:
      return <></>;
  }
};

export const FilePreview: React.FC<{
  uploadType: FILE_TYPE_TYPE;
  url: string;
}> = ({ uploadType, url }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      {renderFilePreview(uploadType, url, onOpen)}
      <ModalElem open={isOpen} onClose={onClose}>
        {uploadType === 'image' && <img src={url} />}
        {uploadType === 'video' && (
          <VideoStyled controls style={{ width: '100%', height: 'auto' }}>
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </VideoStyled>
        )}
      </ModalElem>
    </>
  );
};

const PlayImg = styled.img`
  height: 18px;
  width: auto;
`;

const VideoPreviewContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 137px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${Spacing(4)};
`;

const DotPlay = styled.div`
  height: 50px;
  width: 50px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.12);
  justify-content: center;
`;

const UploadedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 137px;
`;

const UploadImg = styled.img`
  width: ${Spacing(8)};
  height: ${Spacing(8)};
`;

const VideoStyled = styled.video`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DROPDOWN
  ]}px !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const ImageStyled = styled.img`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.DROPDOWN
  ]}px !important;
  max-height: 274px;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;
