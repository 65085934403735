import { OPTION_INTER } from '../../data/user/constant';
import { i18n } from '../../lib/lang';
import { CATEGORY_ENUM } from '../profile-psycho-create-category/constant';

export const MODULE_NAME = 'PROFILE_USER_UPDATE_DATA_MODULE_NAME';
export const MODULE_DATA_NAME = 'PROFILE_USER_DATA_MODULE_NAME'; // На перенос в соответсвующий модуль

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  NAME = 'name',
  PHONE = 'phone',
  REQUEST_LIST = 'requestList',
  REQUEST_CUSTOM = 'requestCustom',
  LGBT_FRENDLY = 'lgbtFrendly',
  AGE = 'age',
  GENDER = 'gender',
  PHOTO = 'photo',
  METHOD_LIST = 'methodList',
  METHOD_CUSTOM = 'methodCustom',
  NEED_GENDER = 'needGender',
  NEED_AGE = 'needAge',
  ABOUT = 'about',
  TIMEZONE = 'timezone',
  PSYCHO_CATEGORY = 'psychoCategory',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.NAME]: string;
  [FORM_VALUE_ENUM.PHONE]: string;
  [FORM_VALUE_ENUM.REQUEST_LIST]: OPTION_INTER[];
  [FORM_VALUE_ENUM.REQUEST_CUSTOM]: string;
  [FORM_VALUE_ENUM.LGBT_FRENDLY]: string | null;
  [FORM_VALUE_ENUM.AGE]: string | null;
  [FORM_VALUE_ENUM.GENDER]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.METHOD_LIST]: OPTION_INTER[];
  [FORM_VALUE_ENUM.METHOD_CUSTOM]: string;
  [FORM_VALUE_ENUM.NEED_GENDER]: number | null;
  [FORM_VALUE_ENUM.NEED_AGE]: number | null;
  [FORM_VALUE_ENUM.ABOUT]: string;
  [FORM_VALUE_ENUM.TIMEZONE]: string | number;
  [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: string | CATEGORY_ENUM;
}

export const GENDER_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.MALE'),
    value: 1,
  },
  {
    label: i18n.t('USER.USER.SELECT.FEMALE'),
    value: 2,
  },
];

export const LGBT_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.YES'),
    value: 'true',
  },
  {
    label: i18n.t('USER.USER.SELECT.NO'),
    value: 'false',
  },
];

export const API = {
  UPDATE: {
    TYPE: 'PATCH',
    URL: 'user/data-update',
  },
  GET: {
    TYPE: 'GET',
    URL: 'user/data/profile',
  },
  POST: {
    TYPE: 'POST',
    URL: 'user/photo',
  },
  DELETE: {
    TYPE: 'DELETE',
    URL: (id: string | null) => `file/${id}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
