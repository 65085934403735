import React from 'react';
import styled from 'styled-components';

import { SkeletonProfileElem } from '../../common/skeleton-profile';
import { AlertActionElem } from '../../common/alert-action';
import { TextElem } from '../../common/text';

export const Component: React.FC<{
  isLoading: boolean;
  isSuccess: boolean;
  isError?: boolean;
  errorMessage?: string;
}> = ({ isSuccess, isLoading, isError, errorMessage }) => {
  return (
    <>
      {isLoading && <SkeletonProfileElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <TextElem
          tid="COMMON.SUCCESS.TITLE"
          size="mainHeadTitle"
          color="default"
        />
      )}
    </>
  );
};
