import { GENDER_ENUM } from '../../data/user/constant';
import { CATEGORY_ENUM } from '../profile-psycho-create-category/constant';

export const MODULE_NAME = 'ORDER_ITEM_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  GET: {
    TYPE: 'GET',
    URL: (id?: string) => `category/order/${id}`,
  },
  POST: {
    TYPE: 'POST',
    URL: 'consultation/create',
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

export interface CATEGORY_RAW_INTER {
  id: string;
  category: CATEGORY_ENUM;
}

export interface PHOTO_INTER {
  id: string;
  url: string;
  name: string;
}

export interface USER_RAW_INTER {
  id: string;
  name: string;
  aboutMyService: string;
  age: number;
  gender: GENDER_ENUM;
  timezone: number;
  lgbtFrendly: boolean;
  duration: string;
  photo: PHOTO_INTER;
  category: CATEGORY_RAW_INTER[];
}

export interface DATA_RAW_INTER {
  id: string;
  createDate: string;
  updateDate: string;
  description: string;
  price: number | null;
  category: CATEGORY_ENUM[];
  duration: number;
  user: USER_RAW_INTER;
}

export interface CATEGORY_INTER extends Omit<CATEGORY_RAW_INTER, ''> {}

export enum FORM_VALUE_ENUM {
  CATEGORY = 'category',
  DATE = 'date',
  TIME = 'time',
  COMMENT = 'comment',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.CATEGORY]: CATEGORY_ENUM | string;
  [FORM_VALUE_ENUM.DATE]: string;
  [FORM_VALUE_ENUM.COMMENT]: string;
  [FORM_VALUE_ENUM.TIME]: string;
}

export interface USER_INTER
  extends Omit<USER_RAW_INTER, 'category' | 'gender' | 'timezone'> {
  category: {
    value: CATEGORY_ENUM;
    label: string;
    id: string;
  }[];
  gender: string;
  timezone: string;
}

export interface DATA_INTER extends Omit<DATA_RAW_INTER, 'user' | 'gender'> {
  user: USER_INTER;
}
