export enum FONT_FAMILY_ENUM {
  OKS_FREE = 'Oks-Free',
  E_UKRAINE = 'e-Ukraine',
  E_UKRAINE_HEAD = 'e-UkraineHead',

}

export type FONT_FAMILY_TYPE = `${FONT_FAMILY_ENUM}`;

export const COLOR_DATA = {
  [FONT_FAMILY_ENUM.OKS_FREE]: 'Oks-Free',
  [FONT_FAMILY_ENUM.E_UKRAINE_HEAD]: 'e-UkraineHead',
  [FONT_FAMILY_ENUM.E_UKRAINE]: 'e-Ukraine',
};