import React, { useState } from 'react';
import styled from 'styled-components';

import { TextElem } from '../text';
import { COLOR_ENUM, COLOR_TYPE } from '../../theme/color';
import { i18n } from '../../lib/lang';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
} from '../../theme/value';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';

export const Elem: React.FC<{
  listLimit?: number;
  list: string[];
  onToggle?: Function;
  color?: COLOR_TYPE;
  isUnderline?: boolean;
  lineHeight?: string;
}> = ({
  listLimit = 3,
  list,
  onToggle,
  color = 'textSecondary',
  isUnderline,
  lineHeight = '1.6em',
}) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = (e: any) => {
    e.stopPropagation();
    setIsReadMore(!isReadMore);
    onToggle && onToggle();
  };

  const isReadMoreText = list.length > listLimit;
  const isLarge = isReadMore && isReadMoreText;

  return (
    <ul>
      {!isLarge &&
        list.map((e, index) => (
          <CustomListItem key={`method-${index}`}>
            <TextElemInfo
              type="regular"
              color="textSecondary"
              size="input"
              tid={e}
            />
          </CustomListItem>
        ))}
      {isLarge &&
        list.slice(0, listLimit).map((e, index) => (
          <CustomListItem key={`method-${index}`}>
            <TextElemInfo
              type="regular"
              color="textSecondary"
              size="input"
              tid={e}
            />
          </CustomListItem>
        ))}
      {isReadMoreText && (
        <TextStyled
          type="regular"
          color="textPrimary"
          size="input"
          tid={isReadMore ? 'Читати більше' : 'Згорнути'}
          isUnderline={isUnderline}
          onClick={toggleReadMore}
        />
      )}
    </ul>
  );
};

const TextElemInfo = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 12px;
  }
`;

const CustomListItem = styled.li`
  position: relative;
  padding-left: ${Spacing(3)};

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 4px;
    height: 4px;
    background-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  }
`;

const TextStyled = styled(TextElem)<{ isUnderline?: boolean }>`
  text-decoration: ${({ isUnderline }) => isUnderline && 'underline'};
  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_OPACITY_ENUM.HOVER]};
  cursor: pointer;

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }

  @media screen and (max-width: 1160px) {
    font-size: 12px;
  }
`;
