import React, { ReactNode } from 'react';

import styled from 'styled-components';

import { IonFooter } from '@ionic/react';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';

export const Elem: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const isAuth = auth ? auth.logged && auth?.isConfirmEmail : null;

  return isAuth ? (
    <Footer>
      <Container>{children}</Container>
    </Footer>
  ) : (
    <></>
  );
};

const Footer = styled(IonFooter)`
  background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  ::before {
    content: none;
  }
  border-top: 1px solid #d8d5c780;

  @media (min-width: 1161px) {
    display: none !important;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 19px ${Spacing(4)};

  display: grid;
  gap: ${Spacing(4)};
`;
