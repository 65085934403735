import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_GRID_DATA,
  SIZE_GRID_ENUM,
} from '../../theme/size';

import { ButtonElem } from '../../common/button';
import { Spacing } from '../../theme';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { LANDING_ID_ENUM, LANDING_ID_PROPS } from './constant';
import useIntersectionObserver from './hook/useIntersectionObserver';
import { i18n } from '../../lib/lang';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { AUTH_LOGIN_PAGE_PATH } from '../../page/auth-login';
import { AUTH_SIGNUP_PAGE_PATH } from '../../page/auth-signup';
import {
  CONSULTATION_PAGE_PATH,
  CONSULTATION_PAGE_PATH_DYNAMIC,
} from '../../page/consultation';
import { STORE_INTER } from '../../data/auth/constant';

export const Component: React.FC<LANDING_ID_PROPS> = ({ handleNavClick }) => {
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const activeSection = useIntersectionObserver();

  const toggleBurger = () => {
    setIsBurgerOpen(!isBurgerOpen);
  };

  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);
  const isAuth = state ? state.logged && state?.isConfirmEmail : null;

  return (
    <HomeHeader>
      <NavBar isBurgerOpen={isBurgerOpen}>
        {Object.values(LANDING_ID_ENUM).map((section) => (
          <NavLink
            key={section}
            href={`#${section}`}
            onClick={handleNavClick(section)}
            isActive={activeSection === section}
          >
            {i18n.t(`LANDING.HOME_HEADER.NAV_BAR.${section}`)}
          </NavLink>
        ))}
      </NavBar>

      <LogoContainer>
        <img src="./assets/svg/header/syayvoLogo.svg" alt="syaivo Logo" />
      </LogoContainer>

      <ButtonsContainer>
        <LinkStyled
          to={
            isAuth ? CONSULTATION_PAGE_PATH_DYNAMIC('') : AUTH_LOGIN_PAGE_PATH
          }
        >
          <LogRegButton type="login">
            {i18n.t('LANDING.HOME_HEADER.BUTTONS.LOGIN')}
          </LogRegButton>
        </LinkStyled>

        <LinkStyled
          to={
            isAuth ? CONSULTATION_PAGE_PATH_DYNAMIC('') : AUTH_SIGNUP_PAGE_PATH
          }
        >
          <LogRegButton type="register">
            {i18n.t('LANDING.HOME_HEADER.BUTTONS.REGISTER')}
          </LogRegButton>
        </LinkStyled>
      </ButtonsContainer>

      <BurgerIcon
        onClick={toggleBurger}
        aria-expanded={isBurgerOpen}
        isBurgerOpen={isBurgerOpen}
      >
        <span />
        <span />
        <span />
      </BurgerIcon>

      <ButtonsContainerBurger isBurgerOpen={isBurgerOpen}>
        <LinkStyled
          to={
            isAuth ? CONSULTATION_PAGE_PATH_DYNAMIC('') : AUTH_LOGIN_PAGE_PATH
          }
        >
          <LogRegButton type="login">
            {i18n.t('LANDING.HOME_HEADER.BUTTONS.LOGIN')}
          </LogRegButton>
        </LinkStyled>

        <LinkStyled
          to={
            isAuth ? CONSULTATION_PAGE_PATH_DYNAMIC('') : AUTH_SIGNUP_PAGE_PATH
          }
        >
          <LogRegButton type="register">
            {i18n.t('LANDING.HOME_HEADER.BUTTONS.REGISTER')}
          </LogRegButton>
        </LinkStyled>
      </ButtonsContainerBurger>
    </HomeHeader>
  );
};

const LinkStyled = styled(Link)`
  width: max-content;
  @media (max-width: 800px) {
    width: 30%;
    margin: 0 auto;
    min-width: 150px;
  }
`;

const NavBar = styled.div<{ isBurgerOpen: boolean }>`
  display: flex;
  height: ${Spacing(9)};
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    display: ${({ isBurgerOpen }) => (isBurgerOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: ${Spacing(20)};
    left: 0;
    width: 100%;
    height: ${Spacing(50)};
    background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
    background: cover;
    font-size: ${SIZE_GRID_DATA[SIZE_GRID_ENUM.DEFAULT]}px;
    gap: ${Spacing(1)};
  }
`;

const ButtonsContainer = styled.div`
  gap: ${Spacing(2.5)};
  display: flex;
  @media (max-width: 800px) {
    display: none;
  }
`;

const ButtonsContainerBurger = styled.div<{ isBurgerOpen: boolean }>`
  display: none;
  flex-direction: column;
  gap: ${Spacing(2)};

  @media (max-width: 800px) {
    display: ${({ isBurgerOpen }) => (isBurgerOpen ? 'flex' : 'none')};
    position: absolute;
    top: ${Spacing(58)};
    left: 0;
    width: 100%;
    background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
    padding: ${Spacing(4)} 0;
  }
`;

const LogRegButton = styled(ButtonElem)`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_SECONDARY]};
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${Spacing(2.25)};
`;

const Dot = styled.div`
  width: ${Spacing(1.5375)};
  height: ${Spacing(1.5375)};
  background-color: ${COLOR_DATA[COLOR_ENUM.CHIP]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
`;

const HomeHeader = styled.div`
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  padding: 0px ${Spacing(15)} 0px ${Spacing(15)};
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  height: ${Spacing(21)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  // border-top-left-radius: 0px;
  // border-top-right-radius: 0px;
  font-family: 'e-UkraineHead' !important;
  width: 100%;
  max-width: ${Spacing(350)};
  margin: 0 auto;
  display: flex;
  gap: ${Spacing(4)};
  justify-content: space-between;
  align-items: center;
  z-index: 200;

  @media (max-width: 800px) {
    justify-content: space-between;
  }
`;

const BurgerIcon = styled.div<{ isBurgerOpen: boolean }>`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: ${Spacing(6)};
  height: ${Spacing(6)};
  cursor: pointer;
  transition: transform 0.3s ease-in-out;

  span {
    width: 100%;
    height: ${Spacing(0.75)};
    background-color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  @media (max-width: 800px) {
    display: flex;
  }

  ${({ isBurgerOpen }) =>
    isBurgerOpen &&
    css`
      span:nth-child(1) {
        transform: rotate(45deg) translate(6px, 6px);
      }

      span:nth-child(2) {
        opacity: 0;
      }

      span:nth-child(3) {
        transform: rotate(-45deg) translate(6px, -6px);
      }
    `}
`;

const activeStyles = css`
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
`;
const NavLink = styled.a<{ isActive: boolean }>`
  font-family: 'e-Ukraine', sans-serif;
  display: inline-block;
  padding: ${Spacing(2)} ${Spacing(4)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
  }

  ${({ isActive }) => isActive && activeStyles};
`;
