import { CONSULTATION_STATUS } from '../../data/consultation/constant';
import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const confirmAction = ({
  consultationId,
  payload,
}: {
  consultationId?: string;
  payload: {
    status?: CONSULTATION_STATUS;
  };
}) => {
  return HttpRequest({
    method: API.PATCH.TYPE,
    url: API.PATCH.URL(consultationId),
    data: payload,
  });
};
