import React from 'react';

import { FormikValues } from 'formik';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import { FORM_VALUE_ENUM } from './constant';
import { TextElem } from '../../common/text';
import { DoubleContainerElem } from '../../common/double-container';
import closeIcon from '../../asset/svg/common/close.svg';
import { SelectElem } from '../../common/select';
import {
  USER_ITEM_DATA_INTER,
  USER_ROLE_OPTION_LIST,
} from '../../data/user/constant';
import { TIMEZONE_OPTION_LIST } from '../../data/user/timezone';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  close: Function;
  setValue: Function;
  setFieldValue: Function;
  onSubmit: () => void;
  data: USER_ITEM_DATA_INTER;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  close,
  setFieldValue,
  setValue,
  onSubmit,
  data,
}) => {
  return (
    <FormElem onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}

      <GridElem spacing={5}>
        {/* <TitleContainer>
          <TextElem tid="USER.UPDATE.TITLE" type="semi-bold" size="main" />
          <Icon src={closeIcon} onClick={() => close()} />
        </TitleContainer> */}
        <UpdateContainer>
          <FieldTextElem
            name={FORM_VALUE_ENUM.EMAIL}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="USER.UPDATE.FORM.EMAIL"
            value={getFieldValue(FORM_VALUE_ENUM.EMAIL)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.EMAIL)}
            error={isFieldError(FORM_VALUE_ENUM.EMAIL)}
          />
          <SelectElem
            name={FORM_VALUE_ENUM.ROLE}
            onChange={setFieldValue}
            options={USER_ROLE_OPTION_LIST}
            title="USER.UPDATE.FORM.ROLE"
            errorMessage={getFieldError(FORM_VALUE_ENUM.ROLE)}
            error={isFieldError(FORM_VALUE_ENUM.ROLE)}
            value={setValue(USER_ROLE_OPTION_LIST, FORM_VALUE_ENUM.ROLE)}
          />

          <SelectElem
            placeholder="ORDER.ITEM.REGISTRATION.TIMEZONE_PLACEHOLDER"
            name={FORM_VALUE_ENUM.TIMEZONE}
            onChange={setFieldValue}
            options={TIMEZONE_OPTION_LIST}
            dynamic
            title="ORDER.ITEM.REGISTRATION.TIMEZONE"
            errorMessage={getFieldError(FORM_VALUE_ENUM.TIMEZONE)}
            error={isFieldError(FORM_VALUE_ENUM.TIMEZONE)}
            value={setValue(TIMEZONE_OPTION_LIST, FORM_VALUE_ENUM.TIMEZONE)}
          />

          <ButtonElem
            onClick={() => {
              onSubmit();
            }}
            type="submit"
            tid="USER.UPDATE.BUTTON"
            // fill="solid"
            disabled={isSubmitDisabled()}
            color="success"
          />
        </UpdateContainer>

        <InfoWrapper>
          <InfoContainer>
            <TextElem
              type="light"
              lineHeight="1em"
              size="small"
              tid="USER.ADMIN.PHONE"
            />
            <TextElem
              tid={data.phone ?? 'Не вказано'}
              color="textPrimary"
              lineHeight="1em"
              size="semiSmall"
            />
          </InfoContainer>
          <InfoContainer>
            <TextElem
              type="light"
              lineHeight="1em"
              size="small"
              tid="USER.ADMIN.EMAIL"
            />
            <TextElem
              tid={data.email ?? 'Не вказано'}
              color="textPrimary"
              lineHeight="1em"
              size="semiSmall"
            />
          </InfoContainer>

          <InfoContainer>
            <TextElem
              type="light"
              lineHeight="1em"
              size="small"
              tid="USER.ADMIN.ACCOUNT_NUMBER"
            />
            <TextElem
              tid={data.accountNumber ?? 'Не вказано'}
              color="textPrimary"
              lineHeight="1em"
              size="semiSmall"
            />
          </InfoContainer>

          <InfoContainer>
            <TextElem
              type="light"
              lineHeight="1em"
              size="small"
              tid="USER.ADMIN.BANK_NAME"
            />
            <TextElem
              tid={data.bankName ?? 'Не вказано'}
              color="textPrimary"
              lineHeight="1em"
              size="semiSmall"
            />
          </InfoContainer>
        </InfoWrapper>
        {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
        {isSuccess && (
          <AlertActionElem type="success" tid="USER.ADMIN.CHANGE_ROLE" />
        )}
      </GridElem>
    </FormElem>
  );
};

const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(6)};
  padding-right: 170px;

  @media screen and (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const UpdateContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  grid-gap: ${Spacing(3)};
  align-items: end;

  @media screen and (max-width: 1160px) {
    grid-template-columns: 1fr;
  }
`;
