import { CATEGORY_ENUM } from '../profile-psycho-create-category/constant';

export const MODULE_NAME = 'CONSULTATION_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (pageParam: string) => `/consultation/list/?${pageParam}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface PAYMENT_ITEM_DATA_INTER {
  isPaid: boolean | null;
}

export interface CONSULTATION_DATA_RAW_INTER {
  id: string;
  createDate: string;
  updateDate: string;
  price: string;
  status: string;
  date: string;
  duration: number;
  comment: string;
  category: CATEGORY_ENUM;
  psychologist: { name: string };
  user: { name: string };
  payment: PAYMENT_ITEM_DATA_INTER | null;
}

export interface CONSULTATION_ITEM_DATA_INTER
  extends Omit<CONSULTATION_DATA_RAW_INTER, 'category' | 'payment'> {
  paymentStatus: 'PAID' | 'NOT_PAID';
  category: string;
  time: string;
}
export interface CONSULTATION_ITEM_LIST_DATA_INTER {
  list: CONSULTATION_ITEM_DATA_INTER[];
  isEmpty: boolean;
}

export enum STATUS_ENUM {
  // PENDING = 'pending',
  SUCCESS = 'PAID',
  FAILURE = 'NOT_PAID',
  CANCEL = 'CANCEL',
}

export const CHIP_STATUS_PAYMENT_COLOR_BG = {
  // [STATUS_ENUM.PENDING]: '#2E94CD1A',
  [STATUS_ENUM.SUCCESS]: '#2ECDB01A',
  [STATUS_ENUM.FAILURE]: '#A82F2F1A',
  [STATUS_ENUM.CANCEL]: '#A82F2F1A',
};

export const CHIP_STATUS_PAYMENT_COLOR_TEXT = {
  // [STATUS_ENUM.PENDING]: '#2E94CD',
  [STATUS_ENUM.SUCCESS]: '#27AF97',
  [STATUS_ENUM.FAILURE]: '#A82F2F',
  [STATUS_ENUM.CANCEL]: '#A82F2F',
};
