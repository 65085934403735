import React, { FormEvent, FormEventHandler, ReactNode } from 'react';

import styled from 'styled-components';
import { Spacing } from '../../theme';

export const Elem: React.FC<{
  children: ReactNode;
  onSubmit: FormEventHandler;
  className?: string;
  gap?: number;
}> = ({ children, onSubmit, className, gap }) => {
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit(event);
  };
  return (
    <Form gap={gap} onSubmit={handleSubmit} className={className}>
      {children}
      <Input type="button" />
    </Form>
  );
};

const Form = styled.form<{ gap?: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ gap }) => Spacing(gap || 0)};
`;

const Input = styled.input`
  display: none;
`;
