import React from 'react';
import styled from 'styled-components';

import { DATA_INTER } from './constant';
import { HeaderProfile } from './frame/header';
import { Spacing } from '../../theme';
import { Profile } from './frame/profile';
import { About } from './frame/about';
import { FileComponent } from './frame/file';
import { Category } from './frame/category';
import { SkeletonProfileElem } from '../../common/skeleton-profile';
import { AlertActionElem } from '../../common/alert-action';

export const Component: React.FC<{
  data?: DATA_INTER;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  errorMessage: string;
}> = ({ data, isSuccess, isLoading, isError, errorMessage }) => {
  return (
    <>
      {isLoading && <SkeletonProfileElem />}
      {isError && <AlertActionElem text={errorMessage} />}

      {isSuccess && (
        <>
          <HeaderProfile />
          <Container>
            <Profile data={data} />
            <About data={data} />
            <FileComponent data={data} />
            {data?.category &&
              data.category.map((e) => <Category data={{ ...e }} />)}
          </Container>
        </>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  grid-gap: ${Spacing(4)};
  flex-direction: column;
  margin-top: 38px;

  @media screen and (max-width: 1160px) {
    margin-top: ${Spacing(6)};
  }
`;
