import React, { ReactNode } from 'react';

import { i18n } from '../../lib/lang';

import styled, { css } from 'styled-components';
import {
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_TYPE,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_TYPE,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { COLOR_TYPE, COLOR_ENUM } from '../../theme/color';
import { FONT_FAMILY_ENUM, FONT_FAMILY_TYPE } from '../../theme/font';
import { PROPS_TYPE } from './constant';

export const Elem: React.FC<PROPS_TYPE> = ({
  children,
  tid,
  tvalue,
  color,
  size,
  type,
  lineHeight,
  lineThrought,
  oneLine,
  className,
  style = {},
  fontFamily = FONT_FAMILY_ENUM.E_UKRAINE,
  onClick,
  as = 'span',
}) => {
  return (
    <Text
      translate="no"
      as={as}
      onClick={onClick}
      color={color}
      size={size}
      type={type}
      lineHeight={lineHeight}
      lineThrought={lineThrought}
      oneLine={oneLine}
      className={className}
      style={style}
      fontFamily={fontFamily}
    >
      {tid ? i18n.t(tid, tvalue) : children}
    </Text>
  );
};

const Text = styled.span<{
  size?: SIZE_FONT_TYPE;
  color?: COLOR_TYPE;
  type?: SIZE_FONT_WEIGHT_TYPE;
  lineHeight?: string;
  lineThrought?: boolean;
  oneLine?: boolean;
  fontFamily?: FONT_FAMILY_TYPE;
}>`
  display: inline-block;
  text-align: top;

  ${({
    size = SIZE_FONT_ENUM.DEFAULT,
    color = COLOR_ENUM.DEFAULT,
    type = SIZE_FONT_WEIGHT_ENUM.REGULAR,
    lineHeight = 'normal',
    lineThrought = false,
    oneLine = false,
    fontFamily,
  }) => {
    return css`
      font-family: ${fontFamily ? fontFamily : 'e-Ukraine, sans-serif'};
      font-size: ${SIZE_FONT_DATA[size]}px;
      color: ${({ theme }) => theme[color]};
      font-weight: ${SIZE_FONT_WEIGHT_DATA[type]};
      text-decoration: ${lineThrought ? 'line-through' : 'none'};
      overflow: ${oneLine ? 'hidden' : 'visible'};
      text-overflow: ${oneLine ? 'ellipsis' : 'clip'};
      white-space: ${oneLine ? 'nowrap' : ''};
      /* max-width: ${oneLine ? '280px' : '100%'}; */
      line-height: ${lineHeight};
      /* height: ${lineHeight ? 'auto' : `${SIZE_FONT_DATA[size]}px`}; */
    `;
  }}
`;
