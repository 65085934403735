import React from 'react';
import { IonModal } from '@ionic/react';
import styled, { css } from 'styled-components';

import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';

import { PROPS_TYPE } from './constant';

export const Elem: React.FC<PROPS_TYPE> = ({
  open,
  onClose,
  children,
  closeAction,
  className,
}) => {
  const onCloseAction = () => {
    onClose();
    closeAction && closeAction();
  };
  const handleClose = () => onCloseAction();

  return (
    <Modal
      isOpen={open}
      // initialBreakpoint={1}
      // breakpoints={[0, 1]}
      // handleBehavior="cycle"
      onDidDismiss={handleClose}
      trigger="open-modal"
      className={className}
    >
      {children}
    </Modal>
  );
};

const Modal = styled(IonModal)<{ minHeight?: number }>`
  pointer-events: none;
  --height: auto;
  ${({ minHeight }) => css`
    min-height: ${minHeight}px;
  `}
  --backdrop-opacity:0;
  --box-shadow: none;

  background: radial-gradient(
    circle,
    rgba(46, 60, 77, 0.6),
    rgba(9, 25, 45, 0.6)
  );
  backdrop-filter: blur(5px);

  ::part(content) {
    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
    padding: ${Spacing(4)};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[
      SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
    ]}px;

    max-width: ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.SMALL]}px;
    border-radius: ${Spacing(4)};
    top: calc((50% - 200px) / 2);
    width: 450px;
    left: calc((100% - 260px) / 2);
    margin: ${Spacing(5)};
  }
  & > div {
    margin: auto;
    max-width: ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.SMALL]}px;
  }
  &.modal-handle::before {
    display: none;
    content: none;
  }
`;
