import { CONSULTATION_RAW_INTER } from '../../data/consultation/constant';
import {
  GENDER_ENUM,
  methodViewObj,
  requestViewObj,
} from '../../data/user/constant';
import { TIMEZONE_VIEW, TIMEZONE_ENUM } from '../../data/user/timezone';
import { convertDateToLocal } from '../../lib/util/dateConvert';
import { getYearLabel } from '../../lib/util/yearConvert';
import { CATEGORY_VIEW } from '../profile-psycho-create-category/constant';
import { GENDER_VIEW } from '../profile-psycho/constant';

export const convert = (
  values: CONSULTATION_RAW_INTER,
  timezone: number,
): any => {
  // на доработку

  const { time, day } = convertDateToLocal(values.date, timezone);

  const isMethodList =
    Array.isArray(values?.user?.methodList) &&
    values?.user?.methodList.length > 0;

  const isRequestList =
    Array.isArray(values?.user?.requestList) &&
    values?.user?.requestList.length > 0;

  const isNeedGender =
    typeof values?.user?.needGender === 'number' && values?.user?.needGender;

  const data = {
    ...values,
    timeView: time,
    dayView: day,
    category: CATEGORY_VIEW[values?.category],
    user: {
      ...values.user,
      methodList:
        values?.user?.methodList && isMethodList
          ? values.user.methodList.map((e) => e && methodViewObj[e])
          : [],
      requestList:
        values?.user?.requestList && isRequestList
          ? values.user.requestList.map((e) => e && requestViewObj[e])
          : [],
      needGender:
        isNeedGender && GENDER_VIEW[values.user.needGender as GENDER_ENUM],
      timezone:
        values?.user?.timezone &&
        TIMEZONE_VIEW[values.user.timezone as TIMEZONE_ENUM],
      numberTimezone: values?.user?.timezone && values.user.timezone,
      needAge: getYearLabel(values?.user?.needAge || 0),
    },
    psychologist: {
      ...values.psychologist,
      gender:
        values?.psychologist?.gender &&
        GENDER_VIEW[values.psychologist.gender as GENDER_ENUM],
      timezone:
        values?.psychologist?.timezone &&
        TIMEZONE_VIEW[values.psychologist.timezone as TIMEZONE_ENUM],
      age: getYearLabel(values?.psychologist?.age || 0),
    },
  };

  return data;
};
