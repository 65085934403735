import { PickedFile } from '@capawesome/capacitor-file-picker';

export const isFileTypeValid = (file: File, acceptedTypes: string) => {
  const mimeType = file.type;
  const extensions = acceptedTypes.split(',').map((type) => type.trim());
  const baseMimeType = mimeType.replace(/\/.*$/, '');
  return extensions.some((type) => {
    if (type.includes('/')) {
      return type === mimeType;
    }
    return type === baseMimeType;
  });
};

export const isPickedFileTypeValid = (
  file: PickedFile,
  acceptedTypes: string,
): boolean => {
  const mimeType = file.mimeType || (file.blob ? file.blob.type : ''); // Получаем MIME тип из PickedFile или из blob
  const extensions = acceptedTypes.split(',').map((type) => type.trim());
  const baseMimeType = mimeType.replace(/\/.*$/, '');

  return extensions.some((type) => {
    if (type.includes('/')) {
      return type === mimeType;
    }
    return type === baseMimeType;
  });
};
