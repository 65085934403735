import React from 'react';

import { Component } from './component';
import { LANDING_ID_TYPE } from './constant';

export const Container: React.FC = () => {
  const scrollToSection = (id: LANDING_ID_TYPE) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleNavClick =
    (id: LANDING_ID_TYPE) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      scrollToSection(id);
    };

  return <Component handleNavClick={handleNavClick} />;
};
