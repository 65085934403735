import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_TYPE,
} from './constant';
import { Component } from './component';
import { updateAction } from './action';
import { SCHEDULE_LIST_MODULE_NAME } from '../schedule-list';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { requiredArray } from '../../lib/validation/service';
import { convert } from './convert';
import { timeOption } from '../../data/time/constant';
import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';

export const Container: React.FC<{
  time: string[];
  day: string;
  oldDate: string[];
}> = ({ time, day, oldDate }) => {
  const queryClient = useQueryClient();

  const config = {
    [FORM_VALUE_ENUM.DATE]: [],
    [FORM_VALUE_ENUM.TIME]: [requiredArray],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.DATE]: new Date(oldDate[0]),
    [FORM_VALUE_ENUM.TIME]: time.map((e) => ({ label: e, value: e })),
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: () => {},
  });

  const onSuccess = () => {
    queryClient.invalidateQueries(SCHEDULE_LIST_MODULE_NAME);
  };

  const action = useMutation(updateAction, { onSuccess });

  const isSubmitDisabled = () => {
    if (action.isLoading) {
      return true;
    }

    if (!formik.dirty) {
      return true;
    }
    if (!formik.isValid) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const timezone = auth.user ? auth.user.timezone : null;

  const handleSuccess = () => {
    const payload = convert(formik.values, timezone);

    action.mutate({ payload, oldDate });
  };

  const onChangeSelect = (name: string, values: any) => {
    formik.setFieldValue(name, [...values]);
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => {
    return formik.values[name];
  };

  return (
    <Component
      day={day}
      timeOption={timeOption}
      onChangeSelect={onChangeSelect}
      getFieldValue={getFieldValue}
      handleSuccess={handleSuccess}
      isLoading={action.isLoading}
      isSuccess={action.isSuccess}
      errorMessage={getErrorMessage()}
      isError={isError()}
      isSubmitDisabled={isSubmitDisabled}
    />
  );
};
