import React, { ReactNode } from 'react';

import styled, { css } from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';

import { IonRippleEffect } from '@ionic/react';

export const Elem: React.FC<{
  children: ReactNode;
  handleClick?: any;
  wide?: boolean;
  disabled?: boolean;
  control?: boolean;
  className?: string;
}> = ({
  children,
  handleClick,
  wide = false,
  control = false,
  className,
  disabled = false,
}) => {
  return (
    <Card
      disabled={disabled}
      onClick={handleClick}
      wide={wide}
      control={control}
      className={`${className} ${control && 'ion-activatable'} `}
    >
      {children}
      {control && <IonRippleEffect />}
    </Card>
  );
};

const Card = styled.div<{
  wide: boolean;
  control?: boolean;
  disabled?: boolean;
}>`
  padding: 15px 20px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;

  width: 100%;

  ${({ theme, disabled = false }) =>
    disabled &&
    css`
      background-color: ${theme[COLOR_ENUM.BUTTON_DISABLED]};

      & > * > * {
        color: ${theme[COLOR_ENUM.TEXT_BUTTON_DISABLED]};
      }
    `}

  &:not(:disabled) {
    background-color: ${({ theme }) => theme[COLOR_ENUM.BUTTON_PRIMARY]};
    & > * > * {
      color: ${({ theme }) => theme[COLOR_ENUM.BUTTON_TEXT]};
    }
  }
`;
