import React from 'react';
import { Component } from './component';
import { LANDING_ID_TYPE } from '../home-header/constant';
import { scrollToSection } from '../../lib/util/scrollToSection';

export const Container: React.FC = () => {
  const handleNavClick =
    (id: LANDING_ID_TYPE) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      scrollToSection(id);
    };

  return <Component handleNavClick={handleNavClick} />;
};
