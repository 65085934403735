import React from 'react';

import { DATA_INTER, FORM_VALUE_ENUM } from '../constant';
import styled from 'styled-components';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';
import { SelectElem } from '../../../common/select';

export const Category: React.FC<{
  data?: DATA_INTER;
  getFieldValue: Function;
  setFieldValue?: Function;
  setValue: Function;
  isFieldError: Function;
  getFieldError: Function;
}> = ({ data, setFieldValue, getFieldError, isFieldError, setValue }) => {
  return (
    <InfoContainer>
      <SelectWrapper>
        <SelectElem
          placeholder="USER.PSYCHOLOGIST.CATEGORY_PLACEHOLDER"
          name={FORM_VALUE_ENUM.CATEGORY}
          onChange={setFieldValue}
          options={data?.user.category}
          title="USER.PSYCHOLOGIST.CATEGORY_NAME"
          errorMessage={getFieldError(FORM_VALUE_ENUM.CATEGORY)}
          error={isFieldError(FORM_VALUE_ENUM.CATEGORY)}
          value={setValue(data?.user.category, FORM_VALUE_ENUM.CATEGORY)}
        />
      </SelectWrapper>
      <TextContainer>
        <TextElem
          oneLine
          tid="USER.PSYCHO.HEADER.PRICE"
          tvalue={{ value: data?.price }}
          type="bold"
          size="main"
        />
        <TextElem
          oneLine
          type="regular"
          color="textSecondary"
          tid="USER.PSYCHO.HEADER.TIME"
          tvalue={{ value: data?.duration }}
        />
      </TextContainer>
    </InfoContainer>
  );
};

const SelectWrapper = styled.div`
  max-width: ${Spacing(80)};
  width: 100%;

  @media screen and (max-width: 760px) {
    max-width: unset;
  }
`;

const TextContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;
  position: relative;
  bottom: 20px;

  @media screen and (max-width: 760px) {
    bottom: 0px;
  }
`;

const InfoContainer = styled.div`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  display: flex;
  align-items: end;

  gap: ${Spacing(3)};
  padding: ${Spacing(7)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};

  @media screen and (max-width: 760px) {
    flex-direction: column;
    align-items: start;
  }
`;
