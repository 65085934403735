import React, { useCallback, useEffect, useState } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';

import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { TextElem } from '../text';
import { FILE_ENUM, FILE_TYPE, PROPS_TYPE, UPLOADED_FILE } from './constant';
import { isFileTypeValid } from '../../lib/util/isFileTypeValid';

import photoSvg from '../../asset/svg/file/gallery.svg';
import videoSvg from '../../asset/svg/file/video-square.svg';
import documentSvg from '../../asset/svg/file/folder-open.svg';
import draggingFileSvg from '../../asset/svg/common/arrow-down.svg';
import refreshFileSvg from '../../asset/svg/common/refresh.svg';
import trashFileSvg from '../../asset/svg/common/trash.svg';

import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';

import { i18n } from '../../lib/lang';
import { FilePreview } from './frame/render-file-preview';
import { FILE_UPLOAD_INTER } from '../../data/file/constant';
import { generateId } from '@ionic/react';

export const Elem: React.FC<PROPS_TYPE> = ({
  onFileUpload,
  uploadType,
  title,
  dropZoneTitle,
  isDot,
  fileObj = null,
  onRemove,
  disabled,
  isError,
}) => {
  const { maxSize, acceptedTypes } =
    FILE_TYPE[uploadType] || FILE_TYPE[FILE_ENUM.IMAGE];

  const [uploadedFile, setUploadedFile] = useState<UPLOADED_FILE | null>(null);
  const [prefetchFile, setPrefetchFile] = useState<
    null | undefined | FILE_UPLOAD_INTER
  >(null);
  const [fileError, setFileError] = useState<string | null>(null);

  useEffect(() => {
    setPrefetchFile(fileObj);
  }, [fileObj]);

  const url = uploadedFile
    ? URL.createObjectURL(uploadedFile.file)
    : prefetchFile?.url;
  // const fileName = uploadedFile?.name || prefetchFile?.name;

  const isFile = !!prefetchFile || !!uploadedFile;
  const isDisabled = disabled;

  const subTitle: string = {
    [FILE_ENUM.IMAGE]: 'COMMON.FILE.IMAGE_SIZE_TITLE',
    [FILE_ENUM.DOCUMENT]: 'COMMON.FILE.DOCUMENT_SIZE_TITLE',
    [FILE_ENUM.VIDEO]: 'COMMON.FILE.VIDEO_SIZE_TITLE',
  }[uploadType];

  const icon: string = {
    [FILE_ENUM.IMAGE]: photoSvg,
    [FILE_ENUM.DOCUMENT]: documentSvg,
    [FILE_ENUM.VIDEO]: videoSvg,
  }[uploadType];

  const openFileInNewTab = () => {
    if (isDisabled) {
      return;
    }

    if (url && uploadType === 'document') {
      window.open(url, '_blank');
    }
  };

  const errorFormat = i18n.t('COMMON.FILE.ERROR_FORMAT');
  const errorFileSize = i18n.t('COMMON.FILE.ERROR_SIZE');

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (isDisabled) {
        return;
      }

      if (isError) {
        setUploadedFile(null);
        setPrefetchFile(null);
        return;
      }

      if (fileRejections.length > 0) {
        const errors = fileRejections.map(({ file, errors }) => {
          const errorMessage = errors.map((e) => e.message).join(', ');
          return `${file.name} - ${errorMessage}`;
        });
        setFileError(errors.join('\n'));
        setUploadedFile(null);
        setPrefetchFile(null);
        return;
      }

      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];

        if (!isFileTypeValid(file, acceptedTypes)) {
          setFileError(errorFormat);
          setPrefetchFile(null);
          setUploadedFile(null);
        } else if (file.size > maxSize) {
          setFileError(errorFileSize);
          setPrefetchFile(null);
          setUploadedFile(null);
        } else {
          setPrefetchFile(null);
          setUploadedFile({ id: generateId(), file });

          setFileError(null);

          if (fileObj && onRemove && file) {
            onRemove();
          }
        }
      }
    },
    [maxSize, onFileUpload, acceptedTypes],
  );

  const { getRootProps, getInputProps, open, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: true,
      // accept: acceptedTypes.split(', '),
      maxSize,
    } as DropzoneOptions);

  useEffect(() => {
    if (uploadedFile) {
      onFileUpload(uploadedFile);
    }
  }, [uploadedFile]);

  const dropZoneHandler = (open: () => void) => {
    if (isDisabled) {
      return;
    }

    if (!isFile) {
      open();
    } else {
      openFileInNewTab();
    }
  };

  const onRemoveHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isDisabled) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    setUploadedFile(null);
    setPrefetchFile(null);

    if (onRemove) {
      onRemove(prefetchFile?.id || uploadedFile?.id);
    }
  };

  const onUpdateHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isDisabled) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    if (onRemove) {
      onRemove(prefetchFile?.id || uploadedFile?.id);
    }

    open();
  };

  const subTitleString = fileError || subTitle;

  const dropZoneString = isDragActive
    ? 'COMMON.FILE.DRAGGING'
    : fileError
    ? 'COMMON.FILE.ERROR'
    : dropZoneTitle;

  return (
    <>
      <Container>
        {title && (
          <TitleContainer>
            <TextElem
              type="light"
              color="textSecondary"
              size="input"
              tid={title}
            />
            {isDot && <Dot />}
          </TitleContainer>
        )}

        <ClickDropZone onClick={() => dropZoneHandler(open)}>
          <RelativeContainer>
            {isFile && (
              <SettingContainer className="setting">
                <IconSettingBackground
                  isDisabled={isDisabled}
                  onClick={(e) => {
                    onUpdateHandler(e);
                  }}
                >
                  <IconImg src={refreshFileSvg} />
                </IconSettingBackground>
                <IconSettingBackground
                  isDisabled={isDisabled}
                  onClick={onRemoveHandler}
                >
                  <IconImg src={trashFileSvg} />
                </IconSettingBackground>
              </SettingContainer>
            )}
            <Dropzone
              {...getRootProps()}
              isDragging={isDragActive}
              isFile={isFile}
              isError={!!fileError}
            >
              <input {...getInputProps()} />

              {!isFile && (
                <>
                  <ImgFileStyled
                    src={isDragActive ? draggingFileSvg : icon}
                    alt={dropZoneTitle || ''}
                  />
                  <TextContainer>
                    <TextElem
                      color={
                        fileError || isDragActive ? 'textSecondary' : 'default'
                      }
                      size="semiSmall"
                      type="regular"
                      tid={dropZoneString}
                    />
                    {!isDragActive && (
                      <TextElem
                        color={fileError ? 'error' : 'textSecondary'}
                        type="light"
                        size="small"
                        tid={subTitleString}
                      />
                    )}
                  </TextContainer>
                </>
              )}

              {/* {progress && (
                <Progress>
                  <ProgressBar width={progress} />
                </Progress>
              )} */}

              {isFile && (
                <FilePreview uploadType={uploadType} url={url || ''} />
              )}
            </Dropzone>
          </RelativeContainer>
        </ClickDropZone>
      </Container>
    </>
  );
};

const ImgFileStyled = styled.img`
  height: 32px;
  width: auto;
`;

const RelativeContainer = styled.div`
  text-decoration: none;
  position: relative;
`;

export const Progress = styled.div`
  border-radius: 100px;
  max-width: 80%;
  width: 100%;
  height: 4px;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  position: absolute;
`;

export const ProgressBar = styled.div<{ width?: number }>`
  border-radius: 100px;
  width: 100%;
  height: 4px;
  background: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  position: absolute;
  ${({ width }) =>
    css`
      width: ${width}%;
    `}
`;

const IconImg = styled.img`
  height: 14px;
  width: auto;
`;

const ClickDropZone = styled.div`
  width: 100%;
  height: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const SettingContainer = styled.div`
  display: flex;
  gap: ${Spacing(1)};
  z-index: 10;
  cursor: pointer;
  position: absolute;
  right: ${Spacing(3)};
  top: ${Spacing(3)};
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  gap: ${Spacing(3)};
`;

const Dropzone = styled.div<{
  backgroundImage?: string | null;
  isFile?: boolean;
  isDragging: boolean;
  isError?: boolean;
  isDisabled?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  min-height: 137px;
  width: 100%;
  gap: ${Spacing(4)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;

  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  border: 3px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  &:hover {
    opacity: ${({ isDisabled }) =>
      !isDisabled && VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }

  ${({ isFile }) =>
    isFile &&
    css`
      background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
      border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
    `}

  ${({ isDragging, isFile }) =>
    isDragging &&
    !isFile &&
    css`
      background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
      border: 3px dashed ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
    `}

    ${({ isError }) =>
    isError &&
    css`
      background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]} !important;
      border: 1px solid ${({ theme }) => theme[COLOR_ENUM.ERROR]};
    `}
`;

const IconSettingBackground = styled.div<{ isDisabled?: boolean }>`
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.12);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  :hover {
    opacity: ${({ isDisabled }) =>
      !isDisabled && VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;
