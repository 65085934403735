import { HttpRequest } from '../../lib/http';
import { API } from './constant';
import { getUserList } from '../../data/user/action';

export const action = (indentify?: string) => {
  let params = '?';

  if (indentify) {
    params += `indentify=${indentify}&`;
  }

  return HttpRequest({
    method: API.TYPE,
    url: API.URL(params),
  });
};
