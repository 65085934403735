import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';
import { Component } from './component';
import { getAction, removeAction } from './action';

export const Container: React.FC = () => {
  const [visibleCertificate, setVisibleCertificate] = useState<number>(0);
  const [visibleDiploma, setVisibleDiploma] = useState<number>(0);

  const {
    data: preFetchData,
    isSuccess: preFetchSuccess,
    isLoading: preFetchIsLoading,
    refetch,
    isRefetching,
    error,
    isError,
  } = useQuery(MODULE_NAME, getAction, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      // @ts-ignore
      if (data?.diploma?.length < 1) {
        setVisibleDiploma(1);
      } else {
        setVisibleDiploma(0);
      }
      // @ts-ignore
      if (data?.certificate?.length < 1) {
        setVisibleCertificate(1);
      } else {
        setVisibleCertificate(0);
      }
    },
  });

  const onRefetch = () => {
    refetch();
  };

  const deleteAction = useMutation((id: string | null) => removeAction(id), {
    onSuccess: () => {
      refetch();
    },
  });

  const handleDelete = (id: string) => {
    deleteAction.mutate(id);
  };

  const isUploading = deleteAction.isLoading || isRefetching;
  const isLoading = preFetchIsLoading;

  const getErrorMessage = () => {
    if (error) {
      const errorObj = error as ACTION_ERROR_INTER;
      return errorObj?.message;
    }
  };

  //@ts-ignore
  const diplomaLength = 12 - Number(preFetchData?.diploma?.length); // max count of
  //@ts-ignore
  const certificateLength = 12 - Number(preFetchData?.certificate?.length); // max count of

  const isVisibleCertificateCount = visibleCertificate < certificateLength;
  const isVisibleDiplomaCount = visibleDiploma < diplomaLength;

  const handleAddCertificate = () => {
    if (isVisibleCertificateCount) {
      setVisibleCertificate((prev) => prev + 1);
    }
  };

  const handleAddDiploma = () => {
    if (isVisibleDiplomaCount) {
      setVisibleDiploma((prev) => prev + 1);
    }
  };

  const isDisabledDiploma =
    !isVisibleDiplomaCount || isLoading || isUploading || visibleDiploma >= 1;
  const isDisabledCertificate =
    !isVisibleCertificateCount ||
    isLoading ||
    isUploading ||
    visibleCertificate >= 1;

  return (
    <Component
      handleAddCertificate={handleAddCertificate}
      handleAddDiploma={handleAddDiploma}
      isDisabledDiploma={isDisabledDiploma}
      isDisabledCertificate={isDisabledCertificate}
      onRefetch={onRefetch}
      data={preFetchData}
      preFetchSuccess={preFetchSuccess}
      handleDelete={handleDelete}
      isUploading={isUploading}
      isLoading={isLoading}
      isError={isError}
      errorMessage={getErrorMessage()}
      visibleCertificate={visibleCertificate}
      visibleDiploma={visibleDiploma}
    />
  );
};
