import React from 'react';
import styled from 'styled-components';
import { TextElem } from '../../common/text';
import { CardElem } from '../../common/card';
import { GridElem } from '../../common/grid';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { useHistory } from 'react-router';
import { CATALOG_ITEM_LIST } from './constant';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
import { ButtonElem } from '../../common/button';

export const Component: React.FC<{}> = () => {
  const history = useHistory();

  const handleRedirect = (id: number) => {
    history.push(`/psycho-list?categoryList=${id}`);
  };

  return (
    <CardContainer>
      <CardHeader>
        <CardContainerTitle size="mainHeadTitle" fontFamily="Oks-Free">
          {i18n.t('LANDING.HOME_CATALOG.CATALOG_TEXT.TITLE')}
        </CardContainerTitle>
        <CardContainerSubtitle>
          {i18n.t('LANDING.HOME_CATALOG.CATALOG_TEXT.SUBTITLE')}
        </CardContainerSubtitle>
      </CardHeader>
      <GridContainer spacing={6} size="list">
        {CATALOG_ITEM_LIST.map((service, index) => (
          <CardBlock key={index} onClick={() => handleRedirect(service.id)}>
            <CardText>
              <Card>
                <CardImg src={service.imgSrc} alt={service.imgAlt} />
                <CardTitle>{service.title}</CardTitle>
                <CardSubtitle>{service.subtitle}</CardSubtitle>
              </Card>
            </CardText>
            <ButtonElemStyled tid="COMMON.SIGN_UP" />
          </CardBlock>
        ))}
      </GridContainer>
    </CardContainer>
  );
};

const ButtonElemStyled = styled(ButtonElem)`
  margin-top: ${Spacing(3)};
  max-height: 55px;
  align-self: end;
`;

const CardContainer = styled.div`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  background-size: cover;
  padding: 0px ${Spacing(49)} ${Spacing(49)};
  margin: 0 auto;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100%;
  max-width: ${Spacing(350)};
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: 1350px) {
    padding: ${Spacing(25)};
  }

  @media (max-width: 768px) {
    padding: ${Spacing(4)}; // было 20
  }

  @media (max-width: 480px) {
    padding: ${Spacing(4)};
  }
`;

const CardBlock = styled.div`
  display: grid;
  align-self: stretch;
  cursor: pointer;
`;

const CardText = styled.div`
  display: grid;
  gap: 0px;
`;

const CardHeader = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding-bottom: ${Spacing(8)};
`;

const CardTitle = styled(TextElem)`
  font-size: ${Spacing(4.5)};
  padding-bottom: ${Spacing(2)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
`;

const CardContainerTitle = styled(TextElem)`
  padding-bottom: ${Spacing(3)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};

  @media (max-width: 768px) {
    font-size: ${Spacing(15)};
  }

  // @media (max-width: 480px) {
  //   font-size: ${Spacing(5)};
  // }
`;

const CardContainerSubtitle = styled(TextElem)`
  font-size: ${Spacing(3.5)};
  padding-bottom: ${Spacing(2)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};

  @media (max-width: 768px) {
    font-size: ${Spacing(3)};
  }

  @media (max-width: 480px) {
    font-size: ${Spacing(2.5)};
  }
`;

const CardSubtitle = styled(TextElem)`
  font-size: ${Spacing(3.5)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  line-height: 160%;
`;

const CardImg = styled.img`
  padding-bottom: ${Spacing(4)};
  width: 100%;
`;

const GridContainer = styled(GridElem)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: ${Spacing(8)};

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled(CardElem)`
  padding: 5px;
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

export default Component;
