import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { ACTION_ERROR_INTER, MODULE_NAME } from './constant';
import { Component } from './component';
import { getAction, removeAction } from './action';

export const Container: React.FC = () => {
  const {
    data: preFetchData,
    isSuccess: preFetchSuccess,
    isLoading: preFetchIsLoading,
    isError,
    refetch,
    error,
  } = useQuery(MODULE_NAME, getAction);

  const onRefetch = () => {
    refetch();
  };

  const deleteAction = useMutation((id: string | null) => removeAction(id), {
    onSuccess: () => {
      refetch();
    },
  });

  const handleDelete = (id: string) => {
    deleteAction.mutate(id);
  };

  const isLoading = deleteAction.isLoading || preFetchIsLoading;

  const getErrorMessage = () => {
    const errorObj = error as ACTION_ERROR_INTER;
    return errorObj?.message;
  };

  return (
    <Component
      isSuccess={deleteAction.isSuccess}
      onRefetch={onRefetch}
      preFetchIsLoading={preFetchIsLoading}
      data={preFetchData}
      handleDelete={handleDelete}
      isLoading={isLoading}
      preFetchSuccess={preFetchSuccess}
      isError={!!isError}
      errorMessage={getErrorMessage()}
    />
  );
};
