import React from 'react';
import { Tooltip } from 'antd';
import { i18n } from '../../lib/lang';

export const Elem: React.FC<{ tid: string }> = ({ tid, children }) => {
  const text = i18n.t(tid);
  return (
    <Tooltip trigger="hover" color="white" title={text}>
      {children}
    </Tooltip>
  );
};
