import React from 'react';
import ReactDOM from 'react-dom';

import { DATA_INTER } from '../constant';
import { ButtonBackElem } from '../../../common/button-back';
import { PSYCHO_LIST_PAGE_PATH } from '../../../page/psycho-list';
import styled from 'styled-components';
import { Spacing } from '../../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';
import { useHistory } from 'react-router';
import { ButtonElem } from '../../../common/button';
import { COLOR_ENUM } from '../../../theme/color';
import ArrowLeftSvg from '../../../asset/svg/button/arrow-left.svg';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../../theme/value';

export const HeaderOrder: React.FC = () => {
  const history = useHistory();

  const handleAction = (path?: string | boolean) => {
    history.go(-1);
  };
  return (
    <>
      {ReactDOM.createPortal(
        <HeaderContainer>
          <ButtonComponent onClick={() => handleAction()}>
            <img src={ArrowLeftSvg} alt="arrow" />
          </ButtonComponent>
          <TextStyled
            color="textPrimary"
            type="medium"
            size="heading"
            tid="ORDER.ITEM.HEADER"
          />
        </HeaderContainer>,
        document.getElementById('layoutApp') || document.body,
      )}
    </>
  );
};

const TextStyled = styled(TextElem)`
  margin: 0 auto;
`;

const ButtonComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  padding: ${Spacing(3)};
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  align-self: end;
  margin: 0 auto;
  margin-top: ${Spacing(10)};
  padding: 0px 24px;
  align-items: center;
  max-width: calc(
    ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.SMALL]}px + ${Spacing(10 * 2)}
  );

  @media screen and (max-width: 1160px) {
    max-width: calc(
      ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.DEFAULT]}px + ${Spacing(10 * 2)}
    );
  }
`;
