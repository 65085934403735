import React, { useEffect } from 'react';
import { useQuery } from 'react-query';

import {
  ACTION_ERROR_INTER,
  ACTION_RESPONSE_INTER,
  DATA_INTER,
  filterOption,
  MODULE_NAME,
} from './constant';
import { Component } from './component';
import { action } from './action';
import { convert } from './convert';

export const Container: React.FC<{
  filterOption?: filterOption;
}> = ({ filterOption }) => {
  const {
    refetch,
    isLoading,
    isSuccess,
    isRefetching,
    isFetching,
    error: preFetchError,
    isError,
    data: preFetch,
  } = useQuery(
    [MODULE_NAME, filterOption?.psychoCategory],
    () => action(filterOption),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  );

  const error = preFetchError as ACTION_ERROR_INTER;

  useEffect(() => {
    refetch();
  }, [filterOption]);

  const getData = (): DATA_INTER | undefined => {
    const data = preFetch as unknown as ACTION_RESPONSE_INTER;

    if (data) {
      return convert(data);
    }
  };

  return (
    <Component
      selectedCategory={filterOption?.psychoCategory}
      data={getData()}
      isLoading={isLoading || isFetching}
      isError={isError && !isLoading && !!error}
      isSuccess={isSuccess && !isLoading && !isRefetching && !isFetching}
      errorMessage={error?.message}
    />
  );
};
