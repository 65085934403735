import { API, FORM_VALUE_INTER } from './constant';
import { HttpRequest } from '../../lib/http';
import { Dispatch } from '../../lib/store';

export const action =
  (payload: FORM_VALUE_INTER) => async (dispatch: Dispatch) => {};

export const getAction = () => {
  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL,
  });
};

export const postMediaAction = async (
  media:
    | File
    | null
    | {
        url: string;
        id: string;
      },
  type: 'photo' | 'video' | 'diploma' | 'certificate',
) => {
  const formData = new FormData();

  const formDataTypeObj = {
    photo: 'photo',
    video: 'video',
    diploma: 'document',
    certificate: 'document',
  };

  const formDataType = formDataTypeObj[type];

  if (media && media instanceof File) {
    formData.append(formDataType, media);
  }

  let apiEndpoint;
  switch (type) {
    case 'certificate':
      apiEndpoint = API.POST_CERTIFICATE;
      break;

    case 'diploma':
      apiEndpoint = API.POST_DIPLOMA;
      break;
    default:
      throw new Error('Unsupported media type');
  }

  return HttpRequest({
    method: apiEndpoint.TYPE,
    url: apiEndpoint.URL,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const removeAction = (id: string | null) => {
  return HttpRequest({
    method: API.DELETE.TYPE,
    url: API.DELETE.URL(id),
  });
};
