export interface PROPS_TYPE {
  onFileUpload: (file: UPLOADED_FILE) => any;
  uploadType: FILE_TYPE_TYPE;
  title?: string;
  dropZoneTitle: string;
  isDot?: boolean;
  fileObj?: File | null | undefined | any;
  onRemove?: Function;
  disabled?: boolean;
  isError?: boolean;
}

export enum FILE_ENUM {
  IMAGE = 'image',
  DOCUMENT = 'document',
  VIDEO = 'video',
}

export interface UPLOADED_FILE {
  file: File;
  id: string;
}

export type FILE_TYPE_TYPE = `${FILE_ENUM}`;

export const FILE_TYPE = {
  [FILE_ENUM.IMAGE]: {
    maxSize: 10 * 1024 * 1024, // 10MB
    acceptedTypes: 'image/jpeg, image/png',
  },

  [FILE_ENUM.DOCUMENT]: {
    maxSize: 5 * 1024 * 1024, // 5MB
    acceptedTypes:
      'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/png',
  },
  [FILE_ENUM.VIDEO]: {
    maxSize: 100 * 1024 * 1024, // 100MB
    acceptedTypes: 'video/mp4, video/quicktime',
  },
};
