import styled from 'styled-components';

import { COLOR_ENUM } from '../../../theme/color';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { CONSULTATION_DATA_INTER } from '../../../data/consultation/constant';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../../theme/value';

export const InforamationContainer: React.FC<
  Partial<CONSULTATION_DATA_INTER>
> = ({
  timeView,
  dayView,
  duration,
  price,
  psychologist,
  payment,
  paymentDayView,
  paymentView,
  paymentTimeView,
  eventLink,
  cancellationReason,
}) => {
  return (
    <>
      <Block>
        <Column>
          <TitleStyled
            tid="CONSULTATION.ADMIN.TITLE"
            type="medium"
            color="textPrimary"
            size="main"
          />
          <GridItem>
            <Item>
              <Name
                tid="CONSULTATION.ADMIN.DATE"
                type="light"
                color="textSecondary"
                size="input"
              />
              <Description
                tid={`${dayView} о ${timeView}`}
                type="medium"
                color="textPrimary"
                size="main"
              />
            </Item>
            <Item>
              <Name
                tid="CONSULTATION.ADMIN.DURATION_TITLE"
                type="light"
                color="textSecondary"
                size="input"
              />
              <Description
                tid="CONSULTATION.ADMIN.DURATION"
                tvalue={{ value: String(duration) }}
                type="medium"
                color="textPrimary"
                size="main"
              />
            </Item>

            <Item>
              <Name
                tid="CONSULTATION.ADMIN.PRICE_TITLE"
                type="light"
                color="textSecondary"
                size="input"
              />
              <Description
                tid="CONSULTATION.ADMIN.PRICE"
                tvalue={{ value: String(price) }}
                type="medium"
                color="textPrimary"
                size="main"
              />
            </Item>
          </GridItem>
          {cancellationReason && (
            <Item>
              <Name
                tid="CONSULTATION.ADMIN.CANCEL_TITLE"
                type="light"
                color="textSecondary"
                size="input"
              />
              <Description
                tid={cancellationReason}
                type="medium"
                color="textPrimary"
                size="main"
              />
            </Item>
          )}

          {eventLink && (
            <LinkItem>
              <Name
                tid="CONSULTATION.ADMIN.URL_TITLE"
                type="light"
                color="textSecondary"
                size="input"
              />
              <LinkMessage onClick={() => window.open(eventLink, '_blank')}>
                <TextElem
                  oneLine
                  tvalue={{ value: eventLink }}
                  tid="CONSULTATION.ADMIN.URL"
                  size="semiSmall"
                  color="default"
                />
              </LinkMessage>
            </LinkItem>
          )}
        </Column>
        {payment && (
          <Column>
            <TitleStyled
              tid="CONSULTATION.ADMIN.PAYMENT.TITLE"
              type="medium"
              color="textPrimary"
              size="main"
            />
            <GridItem>
              <Item>
                <Name
                  tid="CONSULTATION.ADMIN.DATE"
                  type="light"
                  color="textSecondary"
                  size="input"
                />
                <Description
                  tid={`${paymentDayView} ${paymentTimeView}`}
                  type="medium"
                  color="textPrimary"
                  size="main"
                />
              </Item>
              <Item>
                <Name
                  tid="CONSULTATION.ADMIN.PAYMENT.MANUAL"
                  type="light"
                  color="textSecondary"
                  size="input"
                />
                <Description
                  tid={paymentView}
                  type="medium"
                  color="textPrimary"
                  size="main"
                />
              </Item>
            </GridItem>
          </Column>
        )}
      </Block>
    </>
  );
};

const GridItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${Spacing(5)};
`;

const LinkMessage = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  padding: 15px;

  span {
    text-decoration: underline;
    line-height: 1em;
  }

  cursor: pointer;
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const Name = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 12px;
  }
`;

const Description = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 14px;
  }
`;

const TitleStyled = styled(TextElem)`
  margin-bottom: ${Spacing(2)};
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
  max-width: ${Spacing(100)};
  overflow: hidden;
`;

const LinkItem = styled(Item)`
  @media screen and (max-width: 400px) {
    max-width: ${Spacing(60)};
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
`;

const Block = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  width: 100%;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(6)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;

  @media screen and (max-width: 1160px) {
    padding: ${Spacing(5)};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;

    @media screen and (max-width: 790px) {
      grid-template-columns: 1fr;
      gap: ${Spacing(4)};
    }
  }
`;
