import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'WELLCOME_LOGOUT_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const CATALOG_ITEM_LIST = [
  {
    id: 3,
    imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.3.IMG_SRC')}`,
    imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.3.IMG_ALT')}`,
    title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.3.TITLE')}`,
    subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.3.SUBTITLE')}`,
  },
  {
    id: 1,
    imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.1.IMG_SRC')}`,
    imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.1.IMG_ALT')}`,
    title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.1.TITLE')}`,
    subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.1.SUBTITLE')}`,
  },
  {
    id: 5,
    imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.5.IMG_SRC')}`,
    imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.5.IMG_ALT')}`,
    title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.5.TITLE')}`,
    subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.5.SUBTITLE')}`,
  },
  {
    id: 2,
    imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.2.IMG_SRC')}`,
    imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.2.IMG_ALT')}`,
    title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.2.TITLE')}`,
    subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.2.SUBTITLE')}`,
  },
  {
    id: 4,
    imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.4.IMG_SRC')}`,
    imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.4.IMG_ALT')}`,
    title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.4.TITLE')}`,
    subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.4.SUBTITLE')}`,
  },
  {
    id: 6,
    imgSrc: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.6.IMG_SRC')}`,
    imgAlt: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.6.IMG_ALT')}`,
    title: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.6.TITLE')}`,
    subtitle: `${i18n.t('LANDING.HOME_CATALOG.CATALOG_ITEM_LIST.6.SUBTITLE')}`,
  },
];
