import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { LayoutElem } from '../../common/layout';
import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';

import { AuthRecoveryConfirmContainer } from '../../epic/auth-recovery-confirm';
import queryString from 'query-string';
import { AuthHeader } from '../../common/auth-header';
import { GridElem } from '../../common/grid';

export const Page: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const email = query?.email;

  return (
    <PageElem>
      <LayoutAppElem oneColumn>
        <GridElem spacing={6}>
          <AuthHeader />
          <LayoutElem size="small" direction="column" oneColumn>
            <AuthRecoveryConfirmContainer email={email} />
          </LayoutElem>
        </GridElem>
      </LayoutAppElem>
    </PageElem>
  );
};
