import moment from 'moment';
import 'moment/locale/uk';

const lastMonthDay = (date: string) => {
  const ifLeapYear = Number(date.slice(0, 4)) % 4 == 0;
  const month: any = {
    '01': 31,
    '02': ifLeapYear ? 29 : 28,
    '03': 31,
    '04': 30,
    '05': 31,
    '06': 30,
    '07': 31,
    '08': 31,
    '09': 30,
    '10': 31,
    '11': 30,
    '12': 31,
  };
  return month[date.slice(4, 7)];
};

export const dateConvert = (date: string) => {
  const dateISO = new Date(`${date}`).toISOString();
  const localedDate = moment(`${dateISO}`).locale('en').format('D MMMM YYYY ');
  return localedDate[0].toUpperCase() + localedDate.slice(1);
};

export const monthConvert = (date: string) => {
  return `${date.slice(0, 4)}-${date.slice(4, 7)}-01T00:00:00`;
};

export const monthEndConvert = (date: string) => {
  return `${date.slice(0, 4)}-${date.slice(4, 7)}-${lastMonthDay(
    date,
  )}T00:00:00`;
};

export const convertShortDate = (date: string): any => {
  return moment(date).format('DD/MM/YY');
};
export function convertTime(dateString: string): { time: string } {
  moment.locale('uk');

  const date = moment(dateString);

  const time = date.format('H:mm');

  return { time };
}

export function convertDay(dateString: string): { day: string } {
  moment.locale('uk');

  const date = moment.utc(dateString);

  const day = date.format('D MMMM');

  return { day };
}

export function convertDateToLocal(
  dateString: string,
  userTimezone: number,
): { day: string; time: string } {
  moment.locale('uk');

  const date = moment.utc(dateString).utcOffset(userTimezone * 60);

  const day = date.format('D MMMM');
  const time = date.format('HH:mm');

  return { day, time };
}

export function getUkrainianMonth(month: string): string {
  const months: { [key: string]: string } = {
    '01': 'січня',
    '02': 'лютого',
    '03': 'березня',
    '04': 'квітня',
    '05': 'травня',
    '06': 'червня',
    '07': 'липня',
    '08': 'серпня',
    '09': 'вересня',
    '10': 'жовтня',
    '11': 'листопада',
    '12': 'грудня',
  };
  return months[month];
}

export function extractTimeAndDate(dateTimeStr: string): {
  date: string;
  time: string;
} {
  const time = dateTimeStr.slice(11, 16);

  const dateParts = dateTimeStr.slice(0, 10).split('-');
  const day = dateParts[2];
  const month = getUkrainianMonth(dateParts[1]);

  const formattedDate = `${day} ${month}`;

  return {
    time,
    date: formattedDate,
  };
}
