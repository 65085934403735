import { API } from './constant';
import { HttpRequest } from '../../lib/http';

export const getAction = () => {
  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL,
  });
};

export const removeAction = (id: string | null) => {
  return HttpRequest({
    method: API.DELETE.TYPE,
    url: API.DELETE.URL(id),
  });
};
