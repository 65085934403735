import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import { PageElem } from '../../common/page/index';
import { LayoutAppElem } from '../../common/layout-app/index';
import { LayoutElem } from '../../common/layout/index';
import { LayoutSectionElem } from '../../common/layout-section/index';
import { LayoutFooterElem } from '../../common/layout-footer/index';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app/index';
import { PaymentListContainer } from '../../epic/payment-list';
import { MobileMenuContainer } from '../../epic/mobile-menu';
import { FILTER_OPTION } from '../../epic/consultation-list-admin/constant';
import { PaymentListFilterContainer } from '../../epic/payment-list-filter';

export const Page: React.FC = () => {
  const [filterOption, setFilterOption] = useState<FILTER_OPTION>();

  const setOption = (e: any) => {
    setFilterOption(e);
  };

  return (
    <>
      {ReactDOM.createPortal(
        <PaymentListFilterContainer setOption={setOption} />,
        document.getElementById('layoutApp') || document.body,
      )}
      <PageElem>
        <LayoutAppElem>
          <LayoutElem size="default">
            <LayoutSectionElem>
              <PaymentListContainer filterOption={filterOption} />
            </LayoutSectionElem>
          </LayoutElem>
        </LayoutAppElem>
        <DesktopMenuAppContainer />
        <LayoutFooterElem>
          <MobileMenuContainer />
        </LayoutFooterElem>
      </PageElem>
    </>
  );
};
