import { i18n } from '../../lib/lang';
import { email } from './../../lib/validation/service';

export const MODULE_NAME = 'CONSULTATION_lLIST_FILTER_MODULE_NAME';

export const USER_OPTION_LIST_QUERY_KEY = 'USER_OPTION_LIST_QUERY_KEY';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (pageParam: string) => `/user/option-list/${pageParam}`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}

export interface ACTION_RESPONSE_INTER {
  list: [name: string, id: string, phone: string, email: string];
}

export enum FORM_VALUE_ENUM {
  CONSULTATION_STATUS = 'consultationStatus',
  PAYMENT_STATUS = 'paymentStatus',
  DATE = 'date',
  USERS = 'users',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.CONSULTATION_STATUS]: string;
  [FORM_VALUE_ENUM.PAYMENT_STATUS]: 'paid' | 'not_paid';
}

export const PAYMENT_STATUS_OPTION = [
  {
    label: i18n.t('CONSULTATION.PAYMENT.PAID'),
    value: 'paid',
  },
  {
    label: i18n.t('CONSULTATION.PAYMENT.NOT_PAID'),
    value: 'not_paid',
  },
];

export const CONSULTATION_STATUS_OPTION = [
  {
    label: i18n.t('CONSULTATION.STATUS.SCHEDULED'),
    value: 'Pending',
  },
  {
    label: i18n.t('CONSULTATION.STATUS.CANCEL'),
    value: 'Cancel',
  },
  {
    label: i18n.t('CONSULTATION.STATUS.COMPLETED'),
    value: 'Complete',
  },
];
