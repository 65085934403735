import { USER_ROLE } from '../../data/auth/constant';
import { HttpRequest } from '../../lib/http';
import { API, FILTER_OPTION } from './constant';

export const action = async (filterOption?: FILTER_OPTION) => {
  let params = '';
  if (
    filterOption?.consultationStatus &&
    filterOption?.consultationStatus?.length > 0
  ) {
    params += `consultationStatus=${filterOption.consultationStatus}&`;
  }

  if (filterOption?.paymentStatus) {
    params += `paymentStatus=${filterOption.paymentStatus}&`;
  }
  if (filterOption?.indentify) {
    params += `indentify=${filterOption.indentify}&`;
  }
  if (filterOption?.startDate && filterOption?.endDate) {
    params += `startDate=${filterOption?.startDate}&endDate=${filterOption?.endDate}&`;
  }
  params.slice(0, -1);
  return await HttpRequest({
    method: API.TYPE,
    url: API.URL(params),
  });
};
