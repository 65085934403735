import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useDispatch } from '../../lib/store';
import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  MODULE_NAME,
} from './constant';
import { Component } from './component';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { action as fetch, getAction } from './action';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import {
  number,
  phoneOperator,
  phoneUA,
  required,
} from '../../lib/validation/service';
import { convertPsychologist } from '../../data/user/convert';
import { PSYCHOLOGIST_ITEM_DATA_RAW_INTER } from '../../data/user/constant';
import { convert } from './convert';

const config = {
  [FORM_VALUE_ENUM.NAME]: [required],
  [FORM_VALUE_ENUM.AGE]: [required],
  [FORM_VALUE_ENUM.GENDER]: [required],
  [FORM_VALUE_ENUM.ABOUT_ME]: [required],
  [FORM_VALUE_ENUM.ACCOUNT_NUMBER]: [],
  [FORM_VALUE_ENUM.BANK_NAME]: [],
  [FORM_VALUE_ENUM.IS_FOP]: [],
  [FORM_VALUE_ENUM.TIMEZONE]: [required, number],
  [FORM_VALUE_ENUM.PHONE]: [phoneOperator, phoneUA],
};

const validate = (values: FormikValues) => validation(values, config);

export const Container: React.FC = () => {
  const query = useQueryClient();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState<any>({});

  const action = useMutation((d: FORM_VALUE_INTER) => dispatch(fetch(d)), {
    onSuccess: () => {
      return query.invalidateQueries(MODULE_NAME);
    },
  });

  const preFetch = useQuery(MODULE_NAME, () => getAction(), {
    onSuccess: (data: PSYCHOLOGIST_ITEM_DATA_RAW_INTER) => {
      const convertData = convertPsychologist(data);

      const offsetInMinutes = new Date().getTimezoneOffset();
      const offsetInHours = -offsetInMinutes / 60;

      const values = {
        [FORM_VALUE_ENUM.PHONE]: convertData?.phone || '',
        [FORM_VALUE_ENUM.IS_FOP]: convertData?.isFop || false,
        [FORM_VALUE_ENUM.ACCOUNT_NUMBER]: convertData?.accountNumber || '',
        [FORM_VALUE_ENUM.BANK_NAME]: convertData?.bankName || '',
        [FORM_VALUE_ENUM.NAME]: convertData?.name || 'Без імені',
        [FORM_VALUE_ENUM.AGE]: convertData?.age || '',
        [FORM_VALUE_ENUM.GENDER]: convertData?.gender || '',
        [FORM_VALUE_ENUM.ABOUT_ME]: convertData?.aboutMe || '',
        [FORM_VALUE_ENUM.TIMEZONE]: convertData?.timezone || offsetInHours,
      };

      setInitialValues(values);
    },
  });

  const formik: FormikValues = useFormik({
    initialValues,
    enableReinitialize: true,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      const convertedValues = convert(values);

      return action.mutate(convertedValues);
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading || preFetch.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (preFetch.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (preFetch.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    const preFetchError: ACTION_ERROR_INTER =
      action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
    if (preFetchError) {
      return preFetchError.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  const onChangeSelect = (name: string, values: any) => {
    formik.setFieldValue(name, [...values]);
  };

  return (
    <Component
      onChangeSelect={onChangeSelect}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      setFieldValue={setFieldValue}
      setValue={setValue}
      isLoadingAction={action.isLoading}
      isSuccessAction={action.isSuccess}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      isError={isError()}
      errorMessage={getErrorMessage()}
    />
  );
};
