import { TIMEZONE_VIEW, TIMEZONE_ENUM } from '../../data/user/timezone';
import { CATEGORY_ENUM } from '../profile-psycho-create-category/constant';
import { CATEGORY_VIEW, GENDER_VIEW } from '../profile-psycho/constant';
import { DATA_INTER, DATA_RAW_INTER } from './constant';

export const convert = (data: DATA_RAW_INTER): DATA_INTER => ({
  ...data,
  user: {
    ...data.user,
    gender: GENDER_VIEW[data.user.gender],
    timezone: TIMEZONE_VIEW[data.user.timezone as unknown as TIMEZONE_ENUM],
    category: data.user.category.map((e) => ({
      id: e.id,
      value: e.category,
      label: e.category
        ? CATEGORY_VIEW[Number(e.category) as CATEGORY_ENUM]
        : '',
    })),
  },
});
