import React from 'react';
import styled from 'styled-components';

import { TextElem } from '../../common/text';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';
import { ButtonElem } from '../../common/button';
import { SkeletonFormElem } from '../../common/skeleton-form';
import { FileItemUploadContainer } from '../file-item-upload';

export const Component: React.FC<{
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  diplomaIsLoading?: boolean;
  certificateIsLoading?: boolean;
  isUploading?: boolean;
  handleDelete: Function;
  data: any;
  preFetchSuccess: boolean;
  onRefetch: Function;
  visibleCertificate: number;
  visibleDiploma: number;
  handleAddCertificate: Function;
  handleAddDiploma: Function;
  isDisabledDiploma: boolean;
  isDisabledCertificate: boolean;
}> = ({
  isLoading,
  isError,
  errorMessage,
  isUploading,
  handleDelete,
  data,
  preFetchSuccess,
  onRefetch,
  visibleCertificate,
  visibleDiploma,
  handleAddCertificate,
  handleAddDiploma,
  isDisabledDiploma,
  isDisabledCertificate,
}) => {
  return (
    <>
      {isUploading && <LoaderElem />}
      {isLoading && <SkeletonFormElem />}

      {preFetchSuccess && !isLoading && (
        <Container>
          <FileButtonContainer>
            {data?.diploma?.length > 0 &&
              data.diploma.map((e: File | any, index: number) => (
                <FileItemUploadContainer
                  key={e?.id}
                  type="diploma"
                  isDot={index === 0 ? true : false}
                  title={index === 0 ? 'COMMON.FILE.DIPLOMA_CONTENT_TITLE' : ''}
                  defaultValue={e?.url}
                  onSuccess={() => {
                    onRefetch();
                  }}
                  onReset={() => {
                    handleDelete(e?.id);
                  }}
                />
              ))}

            {[...Array(visibleDiploma)].map((_, index) => (
              <>
                <FileItemUploadContainer
                  key={index}
                  isDot={data?.diploma?.length === 0 ? true : false}
                  title={
                    data?.diploma?.length === 0
                      ? 'COMMON.FILE.DIPLOMA_CONTENT_TITLE'
                      : ''
                  }
                  type="diploma"
                  onSuccess={onRefetch}
                />
              </>
            ))}

            <ButtonElem
              tid="COMMON.FILE.UPLOAD"
              type="file"
              onClick={handleAddDiploma}
              disabled={isDisabledDiploma}
            />
          </FileButtonContainer>
          <FileButtonContainer>
            {data?.certificate?.length > 0 &&
              data.certificate.map((e: File | any, index: number) => (
                <FileItemUploadContainer
                  key={e?.id}
                  type="certificate"
                  isDot={index === 0 ? true : false}
                  title={
                    index === 0 ? 'COMMON.FILE.CERTIFICATE_CONTENT_TITLE' : ''
                  }
                  defaultValue={e?.url}
                  onSuccess={() => {
                    onRefetch();
                  }}
                  onReset={() => {
                    handleDelete(e?.id);
                  }}
                />
              ))}

            {[...Array(visibleCertificate)].map((_, index) => (
              <>
                <FileItemUploadContainer
                  key={index}
                  type="certificate"
                  onSuccess={onRefetch}
                  isDot={data?.certificate?.length === 0 ? true : false}
                  title={
                    data?.certificate?.length === 0
                      ? 'COMMON.FILE.DIPLOMA_CONTENT_TITLE'
                      : ''
                  }
                />
              </>
            ))}

            <ButtonElem
              tid="COMMON.FILE.UPLOAD"
              type="file"
              onClick={handleAddCertificate}
              disabled={isDisabledCertificate}
            />
          </FileButtonContainer>

          <TitleContainer>
            <Dot />
            <TextElem
              tid="USER.PSYCHOLOGIST.REQUIRED"
              type="light"
              size="input"
              color="textSecondary"
            />
          </TitleContainer>

          {isError && <AlertActionElem text={errorMessage} />}
        </Container>
      )}
    </>
  );
};

const FileButtonContainer = styled.div`
  display: grid;
  grid-gap: ${Spacing(3)};
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin: ${Spacing(2)} 0;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(7)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
`;
