import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { ACTION_ERROR_INTER, PROPS_TYPE } from './constant';
import { Component } from './component';
import { confirmAction, getDateAction } from './action';
import { CONSULTATION_USER_MODULE_NAME } from '../consultation-user-item';
import { USER_ROLE } from '../../data/auth/constant';
import moment from 'moment';

export const Container: React.FC<PROPS_TYPE> = ({
  role,
  consultationId,
  url,
  timezone,
  duration,
  isCompleteClient,
  isCompletePsycho,
  isPaid,
}) => {
  const queryClient = useQueryClient();

  const [isStartConsultation, setIsStartConsultation] = useState(false);

  const consultationTime = useQuery(
    'CONSULTATION_TIME',
    () => getDateAction({ consultationId }),
    {
      onSuccess: (data) => {
        const now = moment();

        // Часовой пояс пользователя в минутах
        const userTimezoneOffset = timezone;

        // Начало консультации с учетом часового пояса
        const consultationStartTime = moment(data?.data).utcOffset(
          userTimezoneOffset,
        );

        // Разница в минутах между текущим временем и началом консультации
        const differenceInMinutes = consultationStartTime.diff(now, 'minutes');

        // Проверяем, началась ли консультация
        if (differenceInMinutes <= 5) {
          setIsStartConsultation(true);
        } else {
          setIsStartConsultation(false);
        }
      },
      refetchInterval: 60000,
    },
  );

  const onSuccess = () => {
    queryClient.invalidateQueries(CONSULTATION_USER_MODULE_NAME);
  };

  const action = useMutation(confirmAction, { onSuccess });

  const isSubmitDisabled = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const handleSuccess = () => {
    if (role === USER_ROLE.USER) {
      action.mutate({ consultationId, payload: { isCompleteClient: true } });
    }
    if (role === USER_ROLE.PSYCHOLOGIST) {
      action.mutate({ consultationId, payload: { isCompletePsycho: true } });
    }
  };

  return (
    <Component
      isPaid={isPaid}
      handleSuccess={handleSuccess}
      isCompleteClient={isCompleteClient}
      isCompletePsycho={isCompletePsycho}
      isTimeSuccess={consultationTime.isSuccess}
      url={url}
      isStartConsultation={isStartConsultation}
      role={role}
      isLoading={action.isLoading}
      isSuccess={action.isSuccess}
      errorMessage={getErrorMessage()}
      isError={isError()}
      isSubmitDisabled={isSubmitDisabled}
    />
  );
};
