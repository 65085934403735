import { HttpRequest } from '../../lib/http';

export const API = {
  GET: {
    TYPE: 'GET',
    URL: ({
      selectedDate,
      psychologistId,
    }: {
      selectedDate: string;
      psychologistId: string;
    }) =>
      `consultation/available-time?selectedDate=${selectedDate}&psychologistId=${psychologistId}`,
  },
};

export const actionTime = ({
  selectedDate,
  psychologistId,
}: {
  selectedDate: string;
  psychologistId: string;
}) => {
  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL({ selectedDate, psychologistId }),
  });
};
