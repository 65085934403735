import React from 'react';

import styled, { css } from 'styled-components';

import { FORM_VALUE_ENUM } from './constant';
import { SelectElem } from '../../common/select';
import { Spacing } from '../../theme';
import { TextElem } from '../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../theme/size';
import consultationIcon from '../../asset/svg/menu-app/consultation.svg';
import { COLOR_ENUM } from '../../theme/color';
import arrowSvg from '../../asset/svg/button/arrow-down.svg';
import arrowExpandedSvg from '../../asset/svg/button/arrow-down-expanded.svg';
import paymentIcon from '../../asset/svg/menu-app/payment.svg';

export const Component: React.FC<{
  isFieldError: Function;
  getFieldError: Function;
  handleSelectUserChange: Function;
  setFieldValue: Function;
  setValue: Function;
  setIndentify: Function;
  isOpenFilter: boolean;
  toggleOpenFilter: Function;
  isLoading?: boolean;
  userList: {
    label: string;
    value: string;
  }[];
}> = ({
  isFieldError,
  getFieldError,
  setFieldValue,
  handleSelectUserChange,
  setValue,
  setIndentify,
  userList,
  isOpenFilter,
  toggleOpenFilter,
}) => {
  const onChangeUser = (
    name: any,
    data: {
      label: string;
      value: string;
      email: string;
      phone: string;
    },
  ) => {
    setIndentify(data?.value);
    setFieldValue(name, { label: name, value: data?.label });
  };

  return (
    <Container>
      <HeaderContainer>
        <img src={paymentIcon} alt="header logo" />
        <TextElem
          color="textPrimary"
          type="medium"
          size="medium"
          tid="CONSULTATION.HEADER"
        />
      </HeaderContainer>
      <SelectContainer>
        <StyledSelect
          backgroundColor="white"
          isClearable
          dynamic
          filterOption={() => true}
          onInputChange={handleSelectUserChange}
          placeholder="CONSULTATION.FILTER.USERS"
          options={userList}
          errorMessage={getFieldError(FORM_VALUE_ENUM.USERS)}
          error={isFieldError(FORM_VALUE_ENUM.USERS)}
          // value={getFieldValue(FORM_VALUE_ENUM.USERS)}
          value={setValue(userList, FORM_VALUE_ENUM.USERS)}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          name={FORM_VALUE_ENUM.USERS}
          onChange={onChangeUser}
        />
      </SelectContainer>
      <SelectContainerMobile isExpanded={isOpenFilter}>
        <FlexContainer onClick={() => toggleOpenFilter()}>
          <TextElem
            type="medium"
            color="textPrimary"
            size="mobileDefault"
            tid="PSYCHO_LIST.FILTER.TITLE"
          />
          <ArrowImg
            className="arrow"
            isExpanded={isOpenFilter}
            src={isOpenFilter ? arrowExpandedSvg : arrowSvg}
            alt="arrow"
          />
        </FlexContainer>
        <SelectInputContainer isExpanded={isOpenFilter}>
          <SelectElem
            isClearable
            dynamic
            filterOption={() => true}
            onInputChange={handleSelectUserChange}
            placeholder="CONSULTATION.FILTER.USERS"
            options={userList}
            errorMessage={getFieldError(FORM_VALUE_ENUM.USERS)}
            error={isFieldError(FORM_VALUE_ENUM.USERS)}
            // value={getFieldValue(FORM_VALUE_ENUM.USERS)}
            value={setValue(userList, FORM_VALUE_ENUM.USERS)}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            name={FORM_VALUE_ENUM.USERS}
            onChange={onChangeUser}
          />
        </SelectInputContainer>
      </SelectContainerMobile>
    </Container>
  );
};

const HeaderContainer = styled.div`
  width: 100%;
  max-width: 166px;
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;

  @media screen and (max-width: 1160px) {
    display: none;
  }
`;

const SelectInputContainer = styled.div<{ isExpanded: boolean }>`
  display: ${({ isExpanded }) => (isExpanded ? 'flex' : 'none')};
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ArrowImg = styled.img<{ isExpanded: boolean }>`
  width: 20px;
  height: 20px;
  margin-left: ${Spacing(2)};
  transition: transform 0.3s ease;
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const SelectContainerMobile = styled.div<{ isExpanded: boolean }>`
  display: none;
  gap: ${Spacing(3)};
  flex-direction: column;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;
  padding: ${Spacing(4)};
  height: ${({ isExpanded }) => (isExpanded ? 'auto' : Spacing(12))};
  width: 100%;

  @media screen and (max-width: 1160px) {
    display: flex;
  }
`;

const SelectContainer = styled.div`
  display: grid;
  gap: ${Spacing(3)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;

  @media screen and (max-width: 1160px) {
    gap: ${Spacing(2)};
    grid-template-columns: 1fr;
    background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  }

  @media screen and (max-width: 1400px) {
    width: unset;
  }
`;

const StyledSelect = styled(SelectElem)<{
  width?: string;
  isOpen?: boolean;
  backgroundColor: string;
}>`
  & .Select__control {
    width: 100%;
    min-height: 48px;
    max-height: 48px;

    @media screen and (max-width: 1160px) {
      background-color: ${({ theme }) =>
        theme[COLOR_ENUM.BACKGROUND_PRIMARY]} !important;
    }
  }

  .Select__value-container--is-multi {
    max-width: calc(100% - 5px) !important;
  }

  max-width: ${({ width }) => (width ? width : '186px')};
  min-width: ${({ width }) => (width ? width : '186px')};

  @media screen and (max-width: 1160px) {
    width: 100%;
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
    max-width: unset;
    min-width: none;

    ${({ isOpen }) =>
      !isOpen &&
      css`
        display: none;
      `}
  }

  @media screen and (max-width: 1130px) {
    max-width: 150px;
    min-width: unset;
  }

  @media screen and (max-width: 1000px) {
    max-width: 120px;
    min-width: unset;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: ${Spacing(6)};
  margin-bottom: ${Spacing(5)};
  align-self: end;
  align-items: center;
  /* + 40 - padding */
  max-width: calc(
    ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.DEFAULT]}px + ${Spacing(10 * 2)}
  );
  padding: 0 ${Spacing(5)};

  @media screen and (max-width: 1160px) {
    justify-content: center;
  }

  @media screen and (max-width: 1160px) {
    padding: 0 10px;
  }
`;
