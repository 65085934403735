import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const updateAction = ({
  payload,
  oldDate,
}: {
  payload: string[];
  oldDate: string[];
}) => {
  return HttpRequest({
    method: API.POST.TYPE,
    url: API.POST.URL,
    data: { payload: [...payload], oldDate: [...oldDate] },
  });
};
