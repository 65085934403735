import React, { useState } from 'react';
import { FormikValues } from 'formik';
import styled from 'styled-components';

import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { TextElem } from '../../common/text';
import { ButtonElem } from '../../common/button';
import { USER_ROLE } from '../../data/auth/constant';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { ModalElem } from '../../common/modal';
import { FieldTextElem } from '../../common/field-text';
import { FORM_VALUE_ENUM } from './constant';
import { TooltipElem } from '../../common/tooltip';
import infoSvg from '../../asset/svg/button/info-circle-white.svg';

export const Component: React.FC<{
  price: number;
  duration: number;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isPaid: boolean;
  isCancel: boolean;
  handlePay: Function;
  handleCancel: (cancelInfo: string) => void;
  role: USER_ROLE;
  isSuccess: boolean;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  formik: FormikValues;
  isSubmitDisabled?: boolean;
  isChangeTimeDisabled?: boolean;
  isCancelDisabled?: boolean;
}> = ({
  isLoading,
  errorMessage,
  isPaid,
  isCancel,
  duration,
  price,
  handlePay,
  handleCancel,
  role,
  isSuccess,
  isError,
  formik,
  getFieldValue,
  getFieldError,
  isFieldError,
  isSubmitDisabled,
  isChangeTimeDisabled,
  isCancelDisabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem
          type="success"
          tid="CONSULTATION.USER.CANCEL_SUCCESS"
        />
      )}

      <Block>
        <InfoContainer>
          <Title
            tid="CONSULTATION.PRICE_DURATION"
            color="textSecondary"
            size="input"
          />
          <TextContainer>
            <TextElem
              oneLine
              tid="USER.PSYCHO.HEADER.PRICE"
              tvalue={{ value: price }}
              type="bold"
              size="large"
            />
            <TextElem
              oneLine
              size="semiSmall"
              type="medium"
              color="textPrimary"
              tid="USER.PSYCHO.HEADER.TIME"
              tvalue={{ value: duration }}
            />
          </TextContainer>
        </InfoContainer>
        <>
          {isPaid && !isCancel && (
            <ChipSuccess>
              <TextElem
                tid="CONSULTATION.USER.PAID"
                color="success"
                size="semiSmall"
              />
            </ChipSuccess>
          )}
        </>
        {!isPaid && !isCancel && role !== USER_ROLE.USER && (
          <ChipError>
            <TextElem
              tid="CONSULTATION.USER.NO_PAID"
              color="noSuccess"
              size="semiSmall"
            />
          </ChipError>
        )}

        {isCancel && (
          <ChipError>
            <TextElem
              tid="CONSULTATION.USER.CANCELLATION"
              color="noSuccess"
              size="semiSmall"
            />
          </ChipError>
        )}

        {role === USER_ROLE.USER && !isPaid && !isCancel && (
          <ButtonContainer>
            <ButtonElemStyled
              tid="CONSULTATION.USER.PAY"
              type="submit"
              onClick={handlePay}
              disabled={isSubmitDisabled}
            />
            <ButtonElemStyled
              onClick={handleOpen}
              tid="CONSULTATION.USER.CANCEL"
              type="cancel"
            />
          </ButtonContainer>
        )}
      </Block>
      <ModalElemStyled onClose={handleClose} open={isOpen}>
        <FieldTextElem
          name={FORM_VALUE_ENUM.CANCELLATION_REASON}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          title="CONSULTATION.USER.CANCEL_INFO"
          placeholder="CONSULTATION.USER.CANCEL_INFO_PLACEHOLDER"
          value={getFieldValue(FORM_VALUE_ENUM.CANCELLATION_REASON)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.CANCELLATION_REASON)}
          error={isFieldError(FORM_VALUE_ENUM.CANCELLATION_REASON)}
        />

        <ButtonElemCancel
          disabled={isCancelDisabled || isChangeTimeDisabled}
          type="submit"
          onClick={() => {
            handleCancel(getFieldValue(FORM_VALUE_ENUM.CANCELLATION_REASON));
            setIsOpen(false);
          }}
        >
          <TextElem tid="CONSULTATION.USER.CANCEL" />

          <TooltipElem tid="CONSULTATION.USER.CANCEL_TOOLTIP">
            <img src={infoSvg} alt="tooltip icon" />
          </TooltipElem>
        </ButtonElemCancel>
      </ModalElemStyled>
    </>
  );
};

const ButtonElemCancel = styled(ButtonElem)`
  margin-top: ${Spacing(4)};
`;

const ModalElemStyled = styled(ModalElem)`
  ::part(content) {
    padding: ${Spacing(6)};
    overflow: visible !important;

    @media screen and (max-width: 720px) {
      padding: ${Spacing(5)};
    }
  }
`;

const Title = styled(TextElem)`
  @media screen and (max-width: 720px) {
    font-size: 12px;
  }
`;

const ButtonElemStyled = styled(ButtonElem)`
  padding: 17px ${Spacing(8)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]};

  span {
    font-size: 14px;
  }
`;

const Chip = styled.div`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  padding: 13.5px ${Spacing(8)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${Spacing(2)};

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;

const ChipSuccess = styled(Chip)`
  border: 1px dashed ${({ theme }) => theme[COLOR_ENUM.SUCCESS]};
`;

const ChipError = styled(Chip)`
  border: 1px dashed ${({ theme }) => theme[COLOR_ENUM.NO_SUCCESS]};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};

  @media screen and (max-width: 720px) {
    flex-direction: column;
    width: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  gap: 2px;
  flex-direction: column;
`;

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(6)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    width: 100%;
    align-items: start;
    gap: ${Spacing(3)};
    padding: ${Spacing(5)};
  }
`;
