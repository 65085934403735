import { FormikValues } from 'formik';
import {
  CATEGORY_ENUM,
  FORM_VALUE_ENUM,
} from '../../epic/profile-psycho-create-category/constant';
import { DEFAULT_DURATION_CATEGORY } from './constant';

export const handleChangeDefaultDurationCategory = (
  category: CATEGORY_ENUM,
  formik: FormikValues,
) => {
  formik.setFieldValue(
    FORM_VALUE_ENUM.DURATION,
    DEFAULT_DURATION_CATEGORY[category],
  );
};
