import React from 'react';
import styled from 'styled-components';

import { CardElem } from '../../../common/card/index';
import { Spacing } from '../../../theme/service';
import { COLOR_DATA, COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../../common/text/index';
import {
  CHIP_STATUS_PAYMENT_COLOR_BG,
  CHIP_STATUS_PAYMENT_COLOR_TEXT,
  CONSULTATION_ITEM_DATA_INTER,
  STATUS_ENUM,
} from '../constant';
import { useHistory } from 'react-router';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { ChipElem } from '../../../common/chip/index';
import { GridElem } from '../../../common/grid/index';
import { AUTH_MODULE_NAME } from '../../../data/auth/index';
import { useSelector } from 'react-redux';
import { STORE_INTER, USER_ROLE } from '../../../data/auth/constant';
import { FlexElem } from '../../../common/flex/index';

export const Card: React.FC<CONSULTATION_ITEM_DATA_INTER> = ({
  id,
  psychologist,
  user,
  category,
  date,
  paymentStatus,
  time,
  duration,
  status,
}) => {
  const state: STORE_INTER = useSelector((s: any) => s[AUTH_MODULE_NAME]);
  const isUser = state.user?.role === USER_ROLE.USER;

  const href = `/consultation/${id}`;
  const history = useHistory();

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      history.push(href);
    }
  };

  const isCancel = status === 'Cancel';

  return (
    <>
      <CardStyled href={href} onClick={onClick}>
        <FlexStyled>
          {!isUser && (
            <TextElem
              oneLine
              size="semiSmall"
              color="default"
              tid={user?.name ?? 'COMMON.NO_NAME'}
            />
          )}
          {isUser && (
            <TextElem
              oneLine
              size="semiSmall"
              color="default"
              tid={psychologist?.name ?? 'COMMON.NO_NAME'}
            />
          )}
          <Chip
            className="paymentStatusAdaptive"
            tid={`CONSULTATION.PAYMENT.${paymentStatus.toUpperCase()}`}
            txColor={CHIP_STATUS_PAYMENT_COLOR_TEXT[paymentStatus]}
            bgColor={CHIP_STATUS_PAYMENT_COLOR_BG[paymentStatus]}
          />
        </FlexStyled>
        <GridStyledModile>
          <TextElem
            oneLine
            size="semiSmall"
            color="textPrimary"
            tid={`${category}`}
          />
          <TextElem
            oneLine
            size="semiSmall"
            color="textPrimary"
            tid="CONSULTATION.LIST.DURATION"
            tvalue={{ value: duration }}
          />
        </GridStyledModile>

        <TextDesktop
          oneLine
          size="semiSmall"
          color="textPrimary"
          tid={`${category}`}
        />
        <TextDesktop
          oneLine
          size="semiSmall"
          color="textPrimary"
          tid="CONSULTATION.LIST.DURATION"
          tvalue={{ value: duration }}
        />
        <GridStyled>
          <TextElem size="semiSmall" color="textPrimary" tid={date} />
          <TextElem
            size="semiSmall"
            color="textPrimary"
            tid="LANG.DATA.DATE_TVALUE"
            tvalue={{ date: time }}
          />
        </GridStyled>
        <Chip
          className="paymentStatus"
          tid={`CONSULTATION.PAYMENT.${
            isCancel ? STATUS_ENUM.CANCEL : paymentStatus.toUpperCase()
          }`}
          txColor={
            CHIP_STATUS_PAYMENT_COLOR_TEXT[
              isCancel ? STATUS_ENUM.CANCEL : paymentStatus
            ]
          }
          bgColor={
            CHIP_STATUS_PAYMENT_COLOR_BG[
              isCancel ? STATUS_ENUM.CANCEL : paymentStatus
            ]
          }
        />
      </CardStyled>
    </>
  );
};

const TextDesktop = styled(TextElem)`
  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const CardStyled = styled.a<{}>`
  display: grid;
  grid-column: span 5;
  // grid-template-columns: subgrid;
  grid-template-columns: 109px 173px 1fr 1fr 1fr;
  gap: 0;
  justify-content: start;
  position: relative;
  align-items: center;
  gap: ${Spacing(12)};
  padding: ${Spacing(4)} ${Spacing(5)};
  background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: 14px;
  height: 56px;
  text-decoration: none;

  & > .paymentStatus {
    justify-self: end;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
    grid-column: 1;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    justify-content: center;
    height: 110px;
    align-items: center;
    gap: ${Spacing(3)};
    // min-width: 288px;

    & > .paymentStatus {
      display: none;
      // justify-self: 0;
      // position: absolute;
      // top: ${Spacing(4)};
      // right: ${Spacing(4)};
    }
  }
`;

const FlexStyled = styled(FlexElem)`
  justify-content: space-between;
  height: 23px;
  width: 100%;

  @media screen and (min-width: 1025px) {
    & > .paymentStatusAdaptive {
      display: none;
    }
  }
`;

const GridStyled = styled(GridElem)`
  grid-template-columns: 1fr 1fr;
  gap: ${Spacing(4)};
  white-space: nowrap;

  @media screen and (max-width: 1024px) {
    grid-template-columns: auto 1fr;
  }
`;

const GridStyledModile = styled(GridStyled)`
  display: none;

  @media screen and (max-width: 1024px) {
    display: grid;
  }
`;

const Chip = styled(ChipElem)<{ bgColor: string; txColor: string }>`
  padding: 6px ${Spacing(3)};
  width: max-content;
  background: ${({ bgColor }) => bgColor && bgColor} !important;
  span {
    color: ${({ txColor }) => txColor && txColor} !important;
  }
`;
