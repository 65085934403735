export function checkTimeDifference(
  startTimeUTC: string,
  userTimezoneOffset: number,
): boolean {
  const currentTimeUTC = new Date();

  const startDateUTC = new Date(startTimeUTC);

  const timeDifferenceInMs = startDateUTC.getTime() - currentTimeUTC.getTime();

  const timeDifferenceInHours = timeDifferenceInMs / (1000 * 60 * 60);

  const userTimeDifference = timeDifferenceInHours + userTimezoneOffset;

  return Math.abs(userTimeDifference) <= 24;
}
