import styled from 'styled-components';
import React from 'react';

import { Spacing } from '../../../theme/service';
import { COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../../common/text/index';
import { CONSULTATION_ITEM_DATA_INTER } from '../constant';
import { useHistory } from 'react-router';
import { ChipElem } from '../../../common/chip/index';
import { GridElem } from '../../../common/grid/index';
import {
  CHIP_STATUS_PAYMENT_COLOR_BG,
  CHIP_STATUS_PAYMENT_COLOR_TEXT,
} from '../../consultation-list/constant';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';

export const Card: React.FC<CONSULTATION_ITEM_DATA_INTER> = ({
  id,
  createDate,
  price,
  user,
  psychologist,
  category,
  date,
  time,
  paymentStatus,
  duration,
}) => {
  const href = `/consultation/${id}`;

  const history = useHistory();

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
      event.preventDefault();
      history.push(href);
    }
  };
  return (
    <>
      <CardMobile href={href} onClick={onClick}>
        <Row>
          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="CONSULTATION.LIST.CREATE_DATE"
            />
            <TextElem
              size="input"
              color="textPrimary"
              tid={createDate}
              oneLine
            />
          </Column>
          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="CONSULTATION.LIST.DATE_TITLE"
            />
            <TextElem
              size="input"
              tid="CONSULTATION.LIST.DATE_VALUE"
              tvalue={{ time, date }}
              oneLine
              color="textPrimary"
            />
          </Column>
        </Row>
        <Divider />
        <RowFlex>
          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="PAYMENT.ITEM.PRICE_TITLE"
            />
            <Row>
              <TextElem
                size="input"
                tid="CONSULTATION.PRICE_TVALUE"
                tvalue={{ price }}
                oneLine
                color="default"
              />
              <TextElem
                oneLine
                size="semiSmall"
                color="textPrimary"
                tid="CONSULTATION.DURATION_TVALUE"
                tvalue={{ value: duration }}
              />
            </Row>
          </Column>
          <Column>
            <Chip
              className="paymentStatus"
              tid={`CONSULTATION.PAYMENT.${paymentStatus}`}
              txColor={CHIP_STATUS_PAYMENT_COLOR_TEXT[paymentStatus]}
              bgColor={CHIP_STATUS_PAYMENT_COLOR_BG[paymentStatus]}
            />
          </Column>
        </RowFlex>
        <Divider />
        <Row>
          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="PAYMENT.ITEM.USER"
            />
            <TextElem
              size="input"
              tid={user?.name ?? 'COMMON.NO_NAME'}
              oneLine
              type="medium"
              color="textPrimary"
            />
          </Column>
          <Column>
            <TextElem
              size="verySmall"
              color="textSecondary"
              oneLine
              tid="PAYMENT.ITEM.PSYCHOLOGIST"
            />
            <TextElem
              size="input"
              type="medium"
              color="textPrimary"
              tid={psychologist?.name ?? 'COMMON.NO_NAME'}
              oneLine
            />
          </Column>
        </Row>
      </CardMobile>
      <CardDesktop key={id} href={href} onClick={onClick}>
        <TextElem
          oneLine
          className="item"
          size="semiSmall"
          color="textPrimary"
          tid={createDate}
        />
        <FlexStyled>
          <TextElem
            oneLine
            className="item"
            size="semiSmall"
            color="textPrimary"
            tid={date}
          />
          <TextElem
            oneLine
            className="item"
            size="semiSmall"
            color="textPrimary"
            tid="LANG.DATA.DATE_TVALUE"
            tvalue={{ date: time }}
          />
        </FlexStyled>

        <TextElem
          oneLine
          className="item"
          size="semiSmall"
          color="textPrimary"
          tid={`${category}`}
        />
        <TextElem
          size="semiSmall"
          color="default"
          tid="CONSULTATION.PRICE_TVALUE"
          tvalue={{ price }}
          oneLine
        />
        <TextElem
          oneLine
          size="semiSmall"
          color="textPrimary"
          tid="CONSULTATION.DURATION_TVALUE"
          tvalue={{ value: duration }}
        />

        <TextElem
          oneLine
          className="userEmail"
          size="semiSmall"
          color="textPrimary"
          tid={user?.email}
        />
        <TextElem
          oneLine
          className="psychoEmail"
          size="semiSmall"
          color="textPrimary"
          tid={psychologist?.email}
        />
        <Chip
          className="paymentStatus"
          tid={`CONSULTATION.PAYMENT.${paymentStatus}`}
          txColor={CHIP_STATUS_PAYMENT_COLOR_TEXT[paymentStatus]}
          bgColor={CHIP_STATUS_PAYMENT_COLOR_BG[paymentStatus]}
        />
      </CardDesktop>
    </>
  );
};

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(216, 213, 199, 0.75);
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  grid-gap: ${Spacing(7)};
`;

const RowFlex = styled.div`
  display: flex;
  width: 100%;
  gap: ${Spacing(5)};
  justify-content: space-between;
`;

const CardMobile = styled.a`
  text-decoration: none;
  display: none;
  gap: 0;
  justify-content: space-between;
  position: relative;
  align-items: center;
  padding: ${Spacing(4)};
  background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.CARD_LIST
  ]}px;

  @media screen and (max-width: 1374px) {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
`;

const CardDesktop = styled.a<{}>`
  width: 100%;
  display: grid;

  grid-template-columns: 101px 167px 122px 51px 77px 109px 109px 110px;

  gap: ${Spacing(7)};
  justify-content: start;
  position: relative;
  align-items: center;
  gap: auto;
  padding: ${Spacing(4)} ${Spacing(5)};
  background: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: 14px;
  height: 56px;
  text-decoration: none;

  @media screen and (max-width: 1374px) {
    display: none;
  }
`;

const Chip = styled(ChipElem)<{ bgColor: string; txColor: string }>`
  min-width: max-content;
  background: ${({ bgColor }) => bgColor && bgColor} !important;
  span {
    color: ${({ txColor }) => txColor && txColor} !important;
  }

  padding: ${Spacing(1.5)} ${Spacing(3)};
`;

const FlexStyled = styled.span`
  display: flex;
  gap: ${Spacing(4)};
`;
