import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const action = ({ pageParam, indentify }: any) => {
  let params = '';
  if (pageParam) params += `skip=${pageParam}`;

  if (indentify) {
    params += `&indentify=${indentify}`;
  }

  params.slice(0, -1);

  return HttpRequest({
    method: API.TYPE,
    url: API.URL(params),
  });
};
