import React from 'react';
import { useDispatch } from '../../lib/store';
import { Component } from './component';
import { resetAuthData } from '../../data/auth/action';
import { useHistory } from 'react-router';
import { HOME_PAGE_PATH } from '../../page/landing';

export const Container: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmitForm = async () => {
    await dispatch(resetAuthData()).then(() => {
      history.push(HOME_PAGE_PATH);
    });
  };
  return <Component onSubmitForm={onSubmitForm} />;
};
