import React, { ReactNode } from 'react';

import styled, { css } from 'styled-components';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { useHistory, useLocation } from 'react-router';
import { TextElem } from '../text';
import { TAB_ELEM_TYPE } from './constant';

export const Elem: React.FC<{ tabList: TAB_ELEM_TYPE[] }> = ({ tabList }) => {
  const router = useLocation();

  const history = useHistory();

  const isActive = (path: string) => router.pathname.includes(path);

  return (
    <Container>
      {tabList.map((item: TAB_ELEM_TYPE) => (
        <TabItem
          key={item.path}
          active={router.pathname.includes(item.path)}
          onClick={() => history.push(item.path)}
        >
          <TextElem
            tid={item.tid}
            color={isActive(item.path) ? 'textPrimary' : 'textSecondary'}
            size="tab"
            type={isActive(item.path) ? 'medium' : 'regular'}
          />
        </TabItem>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  padding: ${Spacing(1)};
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};

  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  gap: ${Spacing(2)};
`;

const TabItem = styled.div<{ active: boolean }>`
  cursor: pointer;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  padding: 14px;
  max-width: 149px;
  max-height: 41px;
  line-height: 0.8em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  box-sizing: border-box;
  width: 100%;
  transition: all 0.2s;

  span {
    height: 14.5px;
  }

  ${({ active }) =>
    active
      ? css`
          border: unset !important;
          background: ${({ theme }) => theme[COLOR_ENUM.TAB]};
        `
      : css`
          :hover {
            & > span {
              color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
            }
          }
        `};
`;
