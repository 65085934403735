export const MODULE_NAME = 'WELLCOME_LOGOUT_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum LANDING_ID_ENUM {
  HOME = 'MAIN',
  SERVICES = 'SERVICES',
  ABOUT = 'ABOUT',
}

export type LANDING_ID_TYPE = `${LANDING_ID_ENUM}`;

export interface LANDING_ID_PROPS {
  handleNavClick: (
    id: LANDING_ID_TYPE,
  ) => (e: React.MouseEvent<HTMLAnchorElement>) => void;
}
