import React from 'react';

import { RouteComponentProps } from 'react-router';
import { LayoutElem } from '../../common/layout';
import { AuthRecoveryContainer } from '../../epic/auth-recovery';
import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';
import { AuthHeader } from '../../common/auth-header';
import { GridElem } from '../../common/grid';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageElem>
      <LayoutAppElem oneColumn>
        <GridElem spacing={6}>
          <AuthHeader />
          <LayoutElem size="small" direction="column" oneColumn>
            <AuthRecoveryContainer />
          </LayoutElem>
        </GridElem>
      </LayoutAppElem>
    </PageElem>
  );
};
