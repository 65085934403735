import { OPTION_INTER } from '../../data/user/constant';

export const convert = (values: any) => ({
  ...values,
  requestList: values.requestList.map((e: OPTION_INTER) => e.value),
  methodList: values.methodList.map((e: OPTION_INTER) => e.value),
  lgbtFrendly: values.lgbtFrendly,
  gender: values?.gender.value,
  timezone: Number(values?.timezone),
});
