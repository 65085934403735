export const MODULE_NAME = 'ADMIN_INVITE_USER_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  EMAIL = 'email',
  ROLE = 'role',
  PASSWORD = 'password',
  NAME = 'name',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.EMAIL]: string;
  [FORM_VALUE_ENUM.PASSWORD]: string;
  [FORM_VALUE_ENUM.ROLE]: string;
  [FORM_VALUE_ENUM.NAME]: string;
}

export const API = {
  TYPE: 'POST',
  URL: '/auth/user-invite',
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
