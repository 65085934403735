import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { config, FORM_VALUE_ENUM, MODULE_NAME } from './constant';
import { Component } from './component';
import { action } from './action';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { convert } from './convert';
import { converPsychoFilter } from '../../data/user/convert';
import { USER_ITEM_DATA_RAW_INTER } from '../../data/user/constant';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { useSelector } from '../../lib/store';
import {
  CATEGORY_ENUM,
  CATEGORY_VIEW,
} from '../profile-psycho-create-category/constant';
import { saveFormikValuesToLocalStorage } from '../../lib/util/formik';

export const Container: React.FC<{
  setOption: Function;
}> = ({ setOption }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('categoryList') as unknown as CATEGORY_ENUM;

  const [initialValues, setInitialValues] = useState<any>({
    [FORM_VALUE_ENUM.REQUEST_LIST]: '',
    [FORM_VALUE_ENUM.METHOD_LIST]: '',
    [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: category
      ? { label: CATEGORY_VIEW[category], value: Number(category) }
      : null,
  });

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));
  const isLogged = () => auth.logged;

  const preFetch = useQuery(MODULE_NAME, action, {
    onSuccess: (data: USER_ITEM_DATA_RAW_INTER) => {
      const convertData = converPsychoFilter(data);
      setInitialValues({
        [FORM_VALUE_ENUM.REQUEST_LIST]: convertData?.requestList,
        [FORM_VALUE_ENUM.METHOD_LIST]: convertData?.methodList,
        [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: category
          ? { value: category, label: CATEGORY_VIEW[category] }
          : convertData.psychoCategory,
      });
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const formik: FormikValues = useFormik({
    initialValues,
    validate: (values) => validation(values, config),
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const isLoading = () => {
    if (!isLogged()) {
      return false;
    }

    if (preFetch.isLoading) {
      return true;
    }
  };

  useEffect(() => {
    setOption(convert(formik.values));
    saveFormikValuesToLocalStorage(formik.values);
  }, [formik.values, category]);

  return <Component formik={formik} isLoading={isLoading()} />;
};
