import { HttpRequest } from '../../lib/http';
import { API, FORM_VALUE_INTER } from './constant';

export const updateAction = ({
  consultationId,
  payload,
}: {
  consultationId?: string;
  payload: FORM_VALUE_INTER;
}) => {
  return HttpRequest({
    method: API.PATCH.TYPE,
    url: API.PATCH.URL(consultationId),
    data: payload,
  });
};

export const createManualAction = ({
  consultationId,
}: {
  consultationId?: string;
}) => {
  return HttpRequest({
    method: API.POST.TYPE,
    url: API.POST.URL(consultationId),
  });
};
