import React from 'react';

import { RouteComponentProps } from 'react-router';

import { AccountSettingsChangeEmailContainer } from '../../epic/account-settings-change-email';
import { AccountSettingsChangePasswordContainer } from '../../epic/account-settings-change-password';
import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutFooterElem } from '../../common/layout-footer';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
// import { AccountSettingsChangeNameContainer } from '../../epic/account-settings-change-name';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import { HeaderContainer } from '../../epic/header';
import settingIcon from '../../asset/svg/menu-app/setting.svg';
import { GridElem } from '../../common/grid';
import { MobileMenuContainer } from '../../epic/mobile-menu';
import { AccountSettingsLogoutContainer } from '../../epic/account-settings-logout';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageElem>
      <LayoutAppElem>
        <HeaderContainer
          icon={settingIcon}
          title="PROJECT.LIST.HEADER"
          size="small"
        />

        <LayoutElem size="small">
          <LayoutSectionElem spacing={8}>
            <GridElem>
              <AccountSettingsChangePasswordContainer />
              <AccountSettingsChangeEmailContainer />
              <AccountSettingsLogoutContainer />
            </GridElem>
            {/* <AccountSettingsChangeNameContainer /> */}
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
      <DesktopMenuAppContainer />
      <LayoutFooterElem>
        <MobileMenuContainer />
      </LayoutFooterElem>
    </PageElem>
  );
};
