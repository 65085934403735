import React from 'react';
import styled from 'styled-components';

import { Spacing } from '../../theme';
import { TextElem } from '../text';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';

export const Elem: React.FC<{}> = () => {
  return (
    <AuthHeader>
      <LogoContainer>
        <LogoSvg src="./assets/svg/header/syayvoLogo.svg" alt="syaivo Logo" />
      </LogoContainer>
    </AuthHeader>
  );
};

const LogoSvg = styled.img`
  width: 129px;
  height: 46px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${Spacing(2.25)};
`;

const AuthHeader = styled.div`
  width: 100%;
  display: flex;
  gap: ${Spacing(4)};
  justify-content: center;
  padding: ${Spacing(28)} 0 0;
  align-items: center;

  margin-bottom: ${Spacing(3)};
`;
