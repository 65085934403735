import { CATEGORY_ENUM } from '../../epic/profile-psycho-create-category/constant';
import { FILE_INTER } from '../file/constant';

export interface CATEGORY_RAW_ITEM {
  id: string;
  createDate: string;
  duration: number;
  updateDate: string;
  category: number;
  description: string;
  price: string;
  video: FILE_INTER | null;
  diploma: FILE_INTER[];
  certificate: FILE_INTER[];
}

export const DEFAULT_DURATION_CATEGORY = {
  [CATEGORY_ENUM.CHILD_COUNSELING]: 50,
  [CATEGORY_ENUM.INDIVIDUAL_CONSULTATION]: 50,
  [CATEGORY_ENUM.CONSULTATION_OF_A_PSYCHIATRIST]: 50,

  [CATEGORY_ENUM.COUPLES_COUNSELING]: 90,
  [CATEGORY_ENUM.GROUP_PSYCHOTHERAPY]: '',
  [CATEGORY_ENUM.GROUP_PSYCHOTHERAPY_TEENAGER]: '',
};
