export const MODULE_NAME = 'WELLCOME_LOGOUT_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const SUBTITLE_LIST = [
  'LANDING.HOME_ABOUT.SUBTITLE.0',
  'LANDING.HOME_ABOUT.SUBTITLE.1',
  'LANDING.HOME_ABOUT.SUBTITLE.2',
  'LANDING.HOME_ABOUT.SUBTITLE.3',
  'LANDING.HOME_ABOUT.SUBTITLE.4',
  'LANDING.HOME_ABOUT.SUBTITLE.5',
  'LANDING.HOME_ABOUT.SUBTITLE.6',
];
