import { FormikValues } from 'formik';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';

export const isFieldError = <Name extends string>(
  name: Name,
  formik: FormikValues,
): boolean => {
  return formik.errors[name] && formik.touched[name] ? true : false;
};

export const getFieldError = <Name extends string>(
  name: Name,
  formik: FormikValues,
): string | undefined =>
  isFieldError(name, formik) ? formik.errors[name] : undefined;

export const setFieldValue = <Name extends string>(
  name: Name,
  e: SELECT_OPTION_ITEM_DATA,
  formik: FormikValues,
) => formik.setFieldValue(name, e.value);

export const saveFormikValuesToLocalStorage = (values: FormikValues) => {
  const keys = Object.keys(values);

  keys.forEach((key) => {
    const value = values[key];

    if (
      Array.isArray(value) &&
      value.every(
        (item) => item.hasOwnProperty('label') && item.hasOwnProperty('value'),
      )
    ) {
      const valuesOnly = value.map((item) => item.value);
      localStorage.setItem(key, JSON.stringify(valuesOnly));
    } else if (typeof value === 'number') {
      localStorage.setItem(key, JSON.stringify(value));
    }
  });
};
