import styled from 'styled-components';
import { COLOR_ENUM } from '../../../theme/color';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import { USER_ROLE } from '../../../data/auth/constant';
import { useHistory } from 'react-router';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { CONSULTATION_DATA_INTER } from '../../../data/consultation/constant';
import { ButtonElem } from '../../../common/button';
import userPhoto from '../../../asset/svg/user/user-photo.svg';
import buttonArrow from '../../../asset/svg/button/arrow-right.svg';

export const UserHeader: React.FC<
  Partial<CONSULTATION_DATA_INTER & { role: USER_ROLE }>
> = ({ user, psychologist, role }) => {
  const history = useHistory();

  const handleRedirect = (id?: string) => {
    history.push(`/psycho-profile/${id}`);
  };

  return (
    <Wrapper>
      <Block>
        <Profile>
          <Photo alt="profile photo" src={user?.photo?.url || userPhoto} />
          <Info>
            <Title
              tid={user?.name ?? 'COMMON.NO_NAME'}
              size="main"
              type="medium"
              color="textPrimary"
            />
            <Description
              tid="CONSULTATION.USER.ROLE"
              size="semiSmall"
              color="textSecondary"
            />
          </Info>
        </Profile>
      </Block>
      <Block>
        <InfoContainer>
          <Profile>
            <Photo
              alt="profile photo"
              src={psychologist?.photo?.url || userPhoto}
            />
            <Info>
              <Title
                tid={psychologist?.name ?? 'COMMON.NO_NAME'}
                size="main"
                type="medium"
                color="textPrimary"
              />
              <Description
                tid="CONSULTATION.PSYCHOLOGIST.ROLE"
                size="semiSmall"
                color="textSecondary"
              />
            </Info>
          </Profile>

          <ButtonStyled
            type="button"
            onClick={() => handleRedirect(psychologist?.id)}
          >
            <ButtonText
              size="main"
              type="medium"
              color="default"
              tid="CONSULTATION.USER.PROFILE_BUTTON"
            />
            <img src={buttonArrow} alt="arrow" />
          </ButtonStyled>
        </InfoContainer>
      </Block>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: ${Spacing(3)};
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: 1160px) {
    flex-direction: column;
  }
`;

const ButtonText = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    display: none;
  }
`;

const Title = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 15px;
  }
`;

const Description = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 12px;
  }
`;

const InfoContainer = styled.div`
  gap: ${Spacing(5)};
  align-items: center;
  display: flex;
  white-space: nowrap;
  justify-content: end;
  width: 100%;
`;

const ButtonStyled = styled(ButtonElem)`
  display: flex;
  gap: ${Spacing(2)};
  padding: 17px;
  border-radius: ${Spacing(2)};
  max-width: ${Spacing(47)};

  span {
    font-size: 14px;
  }

  @media screen and (max-width: 1160px) {
    min-width: 42px;
    max-width: 42px;
    height: 42px;
    padding: unset;
    align-items: center;
    justify-content: center;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Photo = styled.img`
  min-width: ${Spacing(16)};
  max-width: ${Spacing(16)};

  height: ${Spacing(16)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  object-fit: cover;

  @media screen and (max-width: 1160px) {
    min-width: ${Spacing(14)};
    max-width: ${Spacing(14)};
    height: ${Spacing(14)};
  }
`;

const Block = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(4)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;

  @media screen and (max-width: 1160px) {
    padding: ${Spacing(3)};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;
  }
`;

const Profile = styled.div`
  display: flex;
  gap: ${Spacing(4)};
  align-items: center;
  width: 100%;
`;
