import React, { useState } from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import { ModalElem } from '../../../common/modal';
import { SelectElem } from '../../../common/select';
import { FORM_VALUE_ENUM } from '../constant';
import { Spacing } from '../../../theme';
import { ButtonElem } from '../../../common/button';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
} from '../../../theme/size';
import infoSvg from '../../../asset/svg/button/info-circle.svg';
import { TooltipElem } from '../../../common/tooltip';
import { TextElem } from '../../../common/text';
import { DatePickerElem } from '../../../common/date-picker';

export const ModalTimeChange: React.FC<{
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  setFieldValue: Function;
  setValue: Function;
  formik: FormikValues;
  isSubmitDisabled: Function;
  timeOption: {
    label: string;
    value: string;
  }[];
  isFieldDisabled: boolean;
  isTimeLoading: boolean;
  isChangeTimeDisabled: boolean;
}> = ({
  isFieldError,
  getFieldError,
  getFieldValue,
  setFieldValue,
  setValue,
  formik,
  isSubmitDisabled,
  timeOption,
  isTimeLoading,
  isFieldDisabled,
  isChangeTimeDisabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    formik.resetForm();
  };

  return (
    <>
      <ButtonElemStyled
        type="button"
        disabled={isChangeTimeDisabled}
        onClick={handleOpen}
      >
        <TextElem tid="CONSULTATION.USER.REASSIGN" />
        <TooltipElem tid="CONSULTATION.USER.REASSIGN_TOOLTIP">
          <ImgStyled src={infoSvg} alt="tooltip icon" />
        </TooltipElem>
      </ButtonElemStyled>

      <ModalElemStyled onClose={handleClose} open={isOpen}>
        <Double>
          <DatePickerElem
            isDisabled={isFieldDisabled}
            name={FORM_VALUE_ENUM.DATE}
            title="ORDER.ITEM.REGISTRATION.DATE"
            placeholder="ORDER.ITEM.REGISTRATION.DATE_PLACEHOLDER"
            handleChange={(e) => {
              if (e instanceof Date) {
                const formattedDate = e.toLocaleDateString('en-CA');

                formik.setFieldValue(FORM_VALUE_ENUM.DATE, formattedDate);
              }
            }}
          />

          <SelectElem
            isDisabled={isFieldDisabled || !getFieldValue(FORM_VALUE_ENUM.DATE)}
            dynamic
            isLoading={isTimeLoading}
            placeholder="ORDER.ITEM.REGISTRATION.TIME_PLACEHOLDER"
            name={FORM_VALUE_ENUM.TIME}
            onChange={setFieldValue}
            options={timeOption}
            title="ORDER.ITEM.REGISTRATION.TIME"
            errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
            error={isFieldError(FORM_VALUE_ENUM.TIME)}
            value={setValue(timeOption, FORM_VALUE_ENUM.TIME)}
          />
        </Double>
        <ButtonElem
          disabled={isSubmitDisabled()}
          type="submit"
          onClick={() => {
            formik.handleSubmit();
            setIsOpen(false);
          }}
          tid="CONSULTATION.USER.REASSIGN"
        />
      </ModalElemStyled>
    </>
  );
};

const ImgStyled = styled.img`
  height: 13px;
  width: 13px;

  @media screen and (max-width: 720px) {
    height: 11px;
    width: 11px;
  }
`;

const ModalElemStyled = styled(ModalElem)`
  ::part(content) {
    padding: ${Spacing(6)};
    overflow: visible !important;

    @media screen and (max-width: 720px) {
      padding: ${Spacing(5)};
    }
  }
`;

const ButtonElemStyled = styled(ButtonElem)`
  padding: 16px ${Spacing(2)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  max-width: ${Spacing(47)};

  span {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;
  }

  div {
    justify-content: center !important;
  }

  @media screen and (max-width: 720px) {
    max-width: unset;
    padding: 12px ${Spacing(2)};

    span {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SMALL]}px;
    }
  }
`;

const Double = styled.div`
  display: grid;
  grid-gap: ${Spacing(2)};
  grid-template-columns: 1fr 1fr;
  margin-bottom: ${Spacing(6)};
`;
