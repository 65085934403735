import { useState } from 'react';
import { useQuery } from 'react-query';
import { actionTime } from '../../data/time/action';
import { OPTION_ITEM_DATA } from '../../common/select/constant';

interface USE_FETCH_TIME_PROPS {
  selectedDate: string | null;
  psychologistId: string;
  clientTimezone: number;
  isShouldRefetch?: boolean;
}

export const useFetchTime = ({
  selectedDate,
  psychologistId,
  clientTimezone,
  isShouldRefetch,
}: USE_FETCH_TIME_PROPS) => {
  const [timeOption, setTimeOptions] = useState<OPTION_ITEM_DATA[]>([]);

  const fetchTime = useQuery(
    ['time', isShouldRefetch, selectedDate, psychologistId],
    () =>
      actionTime({
        selectedDate: selectedDate || '',
        psychologistId,
      }),
    {
      enabled: !!selectedDate,
      onSuccess: (data: string[]) => {
        const options = data.map((isoString) => {
          const utcTime = new Date(isoString);
          const localTime = new Date(
            utcTime.getTime() + clientTimezone * 3600000,
          );
          const localHour = String(localTime.getUTCHours()).padStart(2, '0');
          return { label: `${localHour}:00`, value: isoString };
        });
        setTimeOptions(options);
      },
    },
  );

  return {
    timeOption,
    isLoading: fetchTime.isLoading,
    isError: fetchTime.isError,
    refetch: fetchTime.refetch,
  };
};
