import React from 'react';

import styled from 'styled-components';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
} from '../../theme/size';
import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { ButtonElem } from '../../common/button';
import { SelectElem } from '../../common/select';
import { FORM_VALUE_ENUM, statusOption } from './constant';
import { FormikValues } from 'formik';

export const Component: React.FC<{
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isSubmitDisabled: Function;
  isSuccess: boolean;
  formik: FormikValues;
  getFieldValue: Function;
  setFieldValue: Function;
  setValue: Function;
  getFieldError: Function;
  isFieldError: Function;
}> = ({
  isLoading,
  isError,
  errorMessage,
  isSuccess,
  isSubmitDisabled,
  setFieldValue,
  getFieldError,
  isFieldError,
  setValue,
  formik,
}) => {
  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem
          type="success"
          tid="CONSULTATION.ADMIN.STATUS_SUCCESS"
        />
      )}
      <Block>
        <SelectElem
          dynamic
          isDisabled={isSubmitDisabled()}
          placeholder="CONSULTATION.ADMIN.STATUS_PLACEHOLDER"
          name={FORM_VALUE_ENUM.STATUS}
          onChange={setFieldValue}
          options={statusOption}
          errorMessage={getFieldError(FORM_VALUE_ENUM.STATUS)}
          error={isFieldError(FORM_VALUE_ENUM.STATUS)}
          value={setValue(statusOption, FORM_VALUE_ENUM.STATUS)}
        />
        <ButtonElem
          disabled={isSubmitDisabled()}
          type="submit"
          onClick={() => {
            formik.handleSubmit();
          }}
          tid="CONSULTATION.ADMIN.STATUS_SAVE"
        />
      </Block>
    </>
  );
};

const Block = styled.div`
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(6)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(2)};
  width: 100%;

  @media screen and (max-width: 1160px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    padding: ${Spacing(5)};
    border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;

    span {
      font-size: 14px;
    }
  }
`;
