import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { AUTH_MODULE_NAME } from '../../data/auth';
import { STORE_INTER, USER_ROLE } from '../../data/auth/constant';
import { useSelector } from '../../lib/store';

import { AUTH_LOGIN_PAGE_PATH, AuthLoginPage } from '../auth-login';
import { AUTH_RECOVERY_PAGE_PATH, AuthRecoveryPage } from '../auth-recovery';
import {
  AUTH_RECOVERY_CONFIRM_PAGE_PATH,
  AuthRecoveryConfirmPage,
} from '../auth-recovery-confirm';
import {
  AUTH_RECOVERY_PASSWORD_PAGE_PATH,
  AuthRecoveryPasswordPage,
} from '../auth-recovery-password';
import { AUTH_SIGNUP_PAGE_PATH, AuthSignupPage } from '../auth-signup';
import { SETTINGS_PAGE_PATH, SettingsPage } from '../settings';

import {
  ACCOUNT_UPDATE_PAGE_PATH,
  AccountUpdatePage,
} from '../account-profile-update';
import {
  AUTH_VERIFICATION_EMAIL_PAGE_PATH,
  AuthVerificationEmailPage,
} from '../auth-verification-email';
import { HOME_PAGE_PATH, HomePage } from '../landing';
import { PAYMENT_PAGE_PATH, PaymentPage } from '../payment';
import { PSYCHO_LIST_PAGE_PATH, PsychoListPage } from '../psycho-list';
import { PSYCHO_PROFILE_PAGE_PATH, PsychoProfilePage } from '../psycho-profile';
import { USER_PAGE_PATH, UserPage } from '../user';
import { ORDER_PAGE_PATH, OrderPage } from '../order';
import { CONSULTATION_PAGE_PATH, ConsultationPage } from '../consultation';
import {
  CONSULTATION_LIST_PAGE_PATH,
  ConsultationListPage,
} from '../consultation-list/index';
import { SCHEDULE_PAGE_PATH, SchedulePage } from '../schedule';
import { PRIVACY_POLICY_PAGE_PATH, PrivacyPolicyPage } from '../privacy-policy';
import { USER_AGREEMENT_PAGE_PATH, UserAgreementPage } from '../user-agreement';
import {
  PUBLIC_CONTRACT_PAGE_PATH,
  PublicContractPage,
} from '../public-contract';
import {
  PUBLIC_AGREEMENT_PAGE_PATH,
  PublicAgreementPage,
} from '../public-agreement';
import {
  COOKIE_USE_POLICY_PAGE_PATH,
  CookieUsePolicyPage,
} from '../cookie-use-policy';
import { SUCCESS_PAGE_PATH, SuccessPage } from '../success';

export const Page: React.FC = () => {
  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);

  const isLogged = state.logged;
  const isConfirmEmail = state?.isConfirmEmail;

  const isAuth = isLogged && isConfirmEmail;

  const isAdmin = state.user?.role === USER_ROLE.ADMIN;
  const isPsychologist = state.user?.role === USER_ROLE.PSYCHOLOGIST;

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          {isAuth ? (
            <Switch>
              <Route path={SETTINGS_PAGE_PATH} component={SettingsPage} exact />
              <Route
                path={AUTH_VERIFICATION_EMAIL_PAGE_PATH}
                component={AuthVerificationEmailPage}
                exact
              />
              <Route
                path={PRIVACY_POLICY_PAGE_PATH}
                component={PrivacyPolicyPage}
                exact
              />
              <Route
                path={USER_AGREEMENT_PAGE_PATH}
                component={UserAgreementPage}
                exact
              />
              <Route
                path={PUBLIC_CONTRACT_PAGE_PATH}
                component={PublicContractPage}
                exact
              />
              <Route
                path={PUBLIC_AGREEMENT_PAGE_PATH}
                component={PublicAgreementPage}
                exact
              />
              <Route
                path={COOKIE_USE_POLICY_PAGE_PATH}
                component={CookieUsePolicyPage}
                exact
              />

              <Route
                path={PSYCHO_PROFILE_PAGE_PATH}
                component={PsychoProfilePage}
                exact
              />
              <Route path={ORDER_PAGE_PATH} component={OrderPage} exact />
              <Route
                path={ACCOUNT_UPDATE_PAGE_PATH}
                component={AccountUpdatePage}
                exact
              />
              <Route
                path={PSYCHO_LIST_PAGE_PATH}
                component={PsychoListPage}
                exact
              />
              <Route path={HOME_PAGE_PATH} component={HomePage} exact />
              <Route
                path={CONSULTATION_LIST_PAGE_PATH}
                component={ConsultationListPage}
                exact
              />
              <Route
                path={CONSULTATION_PAGE_PATH}
                component={ConsultationPage}
                exact
              />
              <Route path={SUCCESS_PAGE_PATH} component={SuccessPage} exact />

              {isAdmin && (
                <Switch>
                  <Route
                    path={PAYMENT_PAGE_PATH}
                    component={PaymentPage}
                    exact
                  />
                  <Route path={USER_PAGE_PATH} component={UserPage} exact />

                  <Route
                    component={() => (
                      <Redirect to={CONSULTATION_LIST_PAGE_PATH} />
                    )}
                  />
                  <Redirect to={CONSULTATION_LIST_PAGE_PATH} />
                </Switch>
              )}
              {isPsychologist && (
                <Switch>
                  <Route
                    path={SCHEDULE_PAGE_PATH}
                    component={SchedulePage}
                    exact
                  />

                  <Route
                    component={() => (
                      <Redirect to={CONSULTATION_LIST_PAGE_PATH} />
                    )}
                  />
                  <Redirect to={CONSULTATION_LIST_PAGE_PATH} />
                </Switch>
              )}
              {!isConfirmEmail && (
                <Redirect to={AUTH_VERIFICATION_EMAIL_PAGE_PATH} />
              )}
              {isConfirmEmail && <Redirect to={CONSULTATION_LIST_PAGE_PATH} />}
              <Route component={() => <Redirect to={HOME_PAGE_PATH} />} />
              <Redirect to={HOME_PAGE_PATH} />
            </Switch>
          ) : (
            <Switch>
              <Route path={HOME_PAGE_PATH} component={HomePage} exact />
              <Route
                path={AUTH_VERIFICATION_EMAIL_PAGE_PATH}
                component={AuthVerificationEmailPage}
                exact
              />
              <Route path={SUCCESS_PAGE_PATH} component={SuccessPage} exact />
              <Route
                path={PRIVACY_POLICY_PAGE_PATH}
                component={PrivacyPolicyPage}
                exact
              />
              <Route
                path={USER_AGREEMENT_PAGE_PATH}
                component={UserAgreementPage}
                exact
              />
              <Route
                path={PUBLIC_CONTRACT_PAGE_PATH}
                component={PublicContractPage}
                exact
              />
              <Route
                path={PUBLIC_AGREEMENT_PAGE_PATH}
                component={PublicAgreementPage}
                exact
              />
              <Route
                path={COOKIE_USE_POLICY_PAGE_PATH}
                component={CookieUsePolicyPage}
                exact
              />
              <Route
                path={AUTH_LOGIN_PAGE_PATH}
                component={AuthLoginPage}
                exact
              />
              <Route path={ORDER_PAGE_PATH} component={OrderPage} exact />
              <Route
                path={AUTH_RECOVERY_PAGE_PATH}
                component={AuthRecoveryPage}
                exact
              />
              <Route
                path={ACCOUNT_UPDATE_PAGE_PATH}
                component={AccountUpdatePage}
                exact
              />
              <Route
                path={AUTH_RECOVERY_CONFIRM_PAGE_PATH}
                component={AuthRecoveryConfirmPage}
                exact
              />
              <Route
                path={AUTH_RECOVERY_PASSWORD_PAGE_PATH}
                component={AuthRecoveryPasswordPage}
                exact
              />
              <Route
                path={AUTH_SIGNUP_PAGE_PATH}
                component={AuthSignupPage}
                exact
              />
              <Route
                path={PSYCHO_LIST_PAGE_PATH}
                component={PsychoListPage}
                exact
              />
              <Route
                path={PSYCHO_PROFILE_PAGE_PATH}
                component={PsychoProfilePage}
                exact
              />
              {isLogged && !isConfirmEmail && (
                <Redirect to={AUTH_VERIFICATION_EMAIL_PAGE_PATH} />
              )}
              <Redirect exact to={HOME_PAGE_PATH} />
            </Switch>
          )}
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};
