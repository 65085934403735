export const MODULE_NAME = 'PROFILE_PSYCHO_UPDATE_REGARD_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export type FILE_TYPE =
  | File
  | null
  | {
      url: string;
      id: string;
    };

export const API = {
  GET: {
    TYPE: 'GET',
    URL: 'user/psychologist-document',
  },
  POST_DIPLOMA: {
    TYPE: 'POST',
    URL: 'user/diploma',
  },

  POST_CERTIFICATE: {
    TYPE: 'POST',
    URL: 'user/certificate',
  },

  DELETE: {
    TYPE: 'DELETE',
    URL: (id: string | null) => `file/${id}`,
  },
};

export enum FORM_VALUE_ENUM {}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
