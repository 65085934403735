import React, { useState } from 'react';
import { TextElem } from '../text';
import styled from 'styled-components';
import { COLOR_ENUM, COLOR_TYPE } from '../../theme/color';
import { i18n } from '../../lib/lang';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
} from '../../theme/value';
import { PROPS_TYPE } from '../text/constant';

export const Elem: React.FC<{
  textLimit?: number;
  text: string;
  onToggle?: Function;
  color?: COLOR_TYPE;
  isUnderline?: boolean;
  lineHeight?: string;
}> = ({
  textLimit = 150,
  text,
  onToggle,
  color = 'textSecondary',
  isUnderline,
  lineHeight = '1.6em',
}) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = (e: any) => {
    e.stopPropagation();
    setIsReadMore(!isReadMore);
    onToggle && onToggle();
  };

  const localText = i18n.t(text);
  const isReadMoreText = localText.length > textLimit;

  return (
    <TextElemInfo
      as="p"
      type="regular"
      color={color}
      size="input"
      lineHeight={lineHeight}
    >
      {isReadMore && isReadMoreText
        ? localText.slice(0, textLimit) + '...'
        : localText}

      {isReadMoreText && (
        <SpanStyled
          isUnderline={isUnderline}
          onClick={toggleReadMore}
          style={{ cursor: 'pointer', marginLeft: '4px' }}
        >
          {isReadMore ? 'Читати більше' : 'Згорнути'}
        </SpanStyled>
      )}
    </TextElemInfo>
  );
};

const TextElemInfo = styled(TextElem)<PROPS_TYPE>`
  font-size: 13px;
  line-height: 1.6em;
  color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  white-space: pre-wrap !important;

  @media screen and (max-width: 1160px) {
    font-size: 12px;
  }
`;

const SpanStyled = styled.span<{ isUnderline?: boolean }>`
  color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
  text-decoration: ${({ isUnderline }) => isUnderline && 'underline'};
  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_OPACITY_ENUM.HOVER]};

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }

  @media screen and (max-width: 1160px) {
    font-size: 12px;
  }
`;
