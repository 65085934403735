export const timeOption = [
  { value: '07:00', label: '07:00' },
  { value: '08:00', label: '08:00' },
  { value: '09:00', label: '09:00' },
  { value: '10:00', label: '10:00' },
  { value: '11:00', label: '11:00' },
  { value: '12:00', label: '12:00' },
  { value: '13:00', label: '13:00' },
  { value: '14:00', label: '14:00' },
  { value: '15:00', label: '15:00' },
  { value: '16:00', label: '16:00' },
  { value: '17:00', label: '17:00' },
  { value: '18:00', label: '18:00' },
  { value: '19:00', label: '19:00' },
  { value: '20:00', label: '20:00' },
  { value: '21:00', label: '21:00' },
  { value: '22:00', label: '22:00' },
  { value: '23:00', label: '23:00' },
];

export const timezoneMap: { [key: number]: string } = {
  '-12': 'Etc/GMT+12',
  '-11': 'Etc/GMT+11',
  '-10': 'Etc/GMT+10',
  '-9': 'Etc/GMT+9',
  '-9.5': 'Etc/GMT+9.5',
  '-8': 'Etc/GMT+8',
  '-7': 'Etc/GMT+7',
  '-6': 'Etc/GMT+6',
  '-5': 'Etc/GMT+5',
  '-4': 'Etc/GMT+4',
  '-3': 'Etc/GMT+3',
  '-2': 'Etc/GMT+2',
  '-2.5': 'Etc/GMT+2.5',
  '-1': 'Etc/GMT+1',
  '0': 'Etc/GMT',
  '1': 'Etc/GMT-1',
  '2': 'Etc/GMT-2',
  '3': 'Etc/GMT-3',
  '4': 'Etc/GMT-4',
  '5': 'Etc/GMT-5',
  '5.5': 'Etc/GMT-5.5',
  '6': 'Etc/GMT-6',
  '6.5': 'Etc/GMT-6.5',
  '7': 'Etc/GMT-7',
  '8': 'Etc/GMT-8',
  '8.75': 'Etc/GMT-8.75',
  '9': 'Etc/GMT-9',
  '9.5': 'Etc/GMT-9.5',
  '10': 'Etc/GMT-10',
  '10.5': 'Etc/GMT-10.5',
  '11': 'Etc/GMT-11',
  '12': 'Etc/GMT-12',
  '12.75': 'Etc/GMT-12.75',
  '13': 'Etc/GMT-13',
  '14': 'Etc/GMT-14',
};
